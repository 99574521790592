<template>
    <div class="page30">
        <h2 id="title">财务现状与预测</h2>
             <TextShade id="p1" ref="p1">
        <template v-slot:shade>
          <p>2021年冲击1亿营收目标，未来三年高速增长</p>
        </template>
      </TextShade>
        <div>
            <div class="hidden" id="animat1">
                <h3>2021年营收组成</h3>
                <div id="pie"></div>
            </div>
            <div class="hidden" id="animat2">
                <h3>未来三年财务数据</h3>
                <BarDoubleChart id="doubleBar" ref="doubleBar" customClass="c_bar"/>
            </div>
        </div>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import BarDoubleChart from "@/components/barDoubleChart/index.vue";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
    data: function() {
        return {
            data: [
                { label: "2021", value1: 10000, value2: 2680 },
                { label: "2022", value1: 15000, value2: 5680 },
                { label: "2023", value1: 20000, value2: 7690 },
            ]
        }
    },
    components: {
        BarDoubleChart,
        TextShade,
    },
    methods: {
        async playPageStart() {
            await this.page30Fn();
            await sleep(3000);
            this.$emit("playEnd");
        },
        async page30Fn() {
            // this.p1 = anyElement({
            //     targets: document.querySelector("#p1"),
            //     isSplitTxt: true,
            //     duration: 1000,
            //     delay: 100,
            //     easing: "easeOutQuint",
            //     inEffect: "fadeIn",
            //     outEffect: "bottomOut",
            // });
            // this.p1.runIn();
            await sleep(100)
             this.$refs.p1.play();
            await sleep(2000)
            $('#animat1').removeClass('hidden')
            this.initPie()
            await sleep(1000)
            $('#animat2').removeClass('hidden')
            this.initBar()
            await sleep(3000)
        },
        initBar() {
            this.$refs.doubleBar.BarChart(this.data, {
                x: (d) => d.label,
                marginLeft: 60,
                marginRight: 40,
                marginBottom: 30,
                marginTop: 30,
                zPadding: 0.05,
                duration: 800,
                delay: 400,
                yPadding: 0.95,
                ease: "easeBack",
                xTickNumber: 6,
                yTickNumber: 10,
                isShowText: true,
                isShowYAxis: false,
                numberSuffix: "w",
            });
        },
        initPie() {
            const myPie = this.$echarts.init(document.getElementById('pie'));
            const option = {
                series: [{
                    type: 'pie',
                    radius: ['30%', '60%'],
                    itemStyle: {
                        borderColor: 'rgba(15, 13, 14, 0.39)',
                        borderWidth: 2,
                    },
                    color: ['#FFFFFF', '#FE9805', '#FF4805'],
                    label: {
                        alignTo: 'edge',
                        formatter: function(params) {
                            if (params.value === 5000) {
                                return `{value|C端：${params.value}w}\n{name|${params.name}}`
                            }
                            if (params.value === 1500) {
                                return `{value|B端：${params.value}w}\n{name|${params.name}}`
                            }
                            if (params.value === 3500) {
                                return `{value|B端：${params.value}w}\n{name|${params.name}}`
                            }
                        },
                        edgeDistance: 10,
                        color: '#fff',
                        fontSize: 12,
                        rich: {
                            name: {
                                lineHeight: 18,
                            },
                            value: {
                                lineHeight: 24,
                            }
                        }
                    },
                    labelLine: {
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: function (params) {
                        const isLeft = params.labelRect.x < myPie.getWidth() / 2;
                        const points = params.labelLinePoints;
                        points[2][0] = isLeft
                        ? params.labelRect.x
                        : params.labelRect.x + params.labelRect.width;
                        return {
                        labelLinePoints: points
                        };
                    },
                    data: [
                        { name: '董蜜SaaS平台', value: 1500 },
                        { name: '金融行业服务解决方案', value: 3500 },
                        { name: '互联网证券信息服务', value: 5000 },
                    ]
                }]
            }
            myPie.setOption(option)
        }
    },
}
</script>
<style lang="less" scoped>
.page30 {
    height: 100%;
    width: 100%;
    background-color: rgba(27, 11, 14, 0.61);
    padding: 64px 120px 100px;
    box-sizing: border-box;
    > h2 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 80px;
    }
    p {
        font-size: 32px;
        color: #FFFFFF;
        font-weight: 400;
        margin-bottom: 36px;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
            background: rgba(15, 13, 14, 0.39);
            border: 1px solid #FFBE60;
            border-radius: 32px;
            height: 640px;
            width: 46%;
            padding: 36px;
            box-sizing: border-box;
            transition: all 0.7s;
            > h3 {
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 48px;
            }
            > div {
                height: 472px;
            }
        }
    }
    .hidden {
        opacity: 0 !important;
        transform: translateY(50px);
    }
    .c_bar {
        /deep/ .text {
            font-size: 26px;
            fill: #fff;
        }
        /deep/ .axis_x {
            font-size: 26px;
            .text {
            fill: #fff;
            }
            .tick_line,
            .domain {
            stroke: #fff;
            }
        }
    }
}
</style>