<template>
  <div class="page18">
    <TextShade id="title" ref="title">
      <template v-slot:shade>
        <h2>B端产品及服务-董蜜SaaS平台部分客户</h2>
      </template>
    </TextShade>

    <div class="marquee hidden" id="myMarquee1">
      <div class="marquee-inner">
        <div class="marquee-content">
          <span>
            <img src="./images/platforms/1.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/2.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/3.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/4.png" alt="" />
          </span>
        </div>
      </div>
    </div>
    <div class="marquee hidden" id="myMarquee2">
      <div class="marquee-inner">
        <div class="marquee-content">
          <span>
            <img src="./images/platforms/5.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/6.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/7.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/8.png" alt="" />
          </span>
        </div>
      </div>
    </div>
    <div class="marquee hidden" id="myMarquee3">
      <div class="marquee-inner">
        <div class="marquee-content">
          <span>
            <img src="./images/platforms/9.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/10.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/11.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/12.png" alt="" />
          </span>
        </div>
      </div>
    </div>
    <div class="marquee hidden" id="myMarquee4">
      <div class="marquee-inner">
        <div class="marquee-content">
          <span>
            <img src="./images/platforms/13.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/14.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/15.png" alt="" />
          </span>
          <span>
            <img src="./images/platforms/16.png" alt="" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import InfiniteMarquee from "infinite-marquee";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page18Fn();
      await sleep(7000);
      this.$emit("playEnd");
    },
    async page18Fn() {
      // this.title = anyElement({
      //   targets: document.querySelector("#title"),
      //   isSplitTxt: true,
      //   duration: 1000,
      //   delay: 200,
      //   easing: "easeOutQuint",
      //   inEffect: "fadeIn",
      //   outEffect: "bottomOut",
      // });
      this.$refs.title.play();
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee1"),
        direction: "right",
        duration: 100,
        css: true,
      });
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee2"),
        direction: "left",
        duration: 100,
        css: true,
        loop: 4,
      });
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee3"),
        direction: "right",
        duration: 100,
        css: true,
      });
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee4"),
        direction: "left",
        duration: 100,
        css: true,
      });
     await sleep(1000);
      $("#myMarquee1").removeClass("hidden");
      $("#myMarquee1").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("#myMarquee2").removeClass("hidden");
      $("#myMarquee2").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("#myMarquee3").removeClass("hidden");
      $("#myMarquee3").addClass("animate__animated animate__bounceInRight ");
       await sleep(1000);
      $("#myMarquee4").removeClass("hidden");
      $("#myMarquee4").addClass("animate__animated animate__bounceInRight ");
    },
  },
};
</script>
<style lang="less" scoped>
@import "infinite-marquee/assets/css/infinite-marquee";
.page18 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow: hidden;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 93px;
  }
  .marquee-content {
    span {
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 16px;
      width: 400px;
    }
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.animate__animated.animate__bounceInRight {
  --animate-duration: 1s;
  // --animate-delay: 2s;
}
</style>