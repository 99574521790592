<template>
  <div class="page16">
    <h2 id="title">B端产品及服务</h2>
    <div class="content">
      <TwoflanksTree ref="twoflanks" id="twoflanks" />
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TwoflanksTree from "@/components/customTree/twoflanks.vue";
import img from "./images/circle16.png";

export default {
  components: {
    TwoflanksTree,
  },
  data: function () {
    return {
      treeData: {
        r: {
          name: "",
          children: [
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>资本运作</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>IPO回购</li><li style='margin-right:6px;color:#FFDAA3FF'>科创板IPO并购重组</li><li style='margin-right:6px;color:#FFDAA3FF'>再融资</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>预警监控</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>股价异动预警</li><li style='margin-right:6px;color:#FFDAA3FF'>商誉预警</li><li style='margin-right:6px;color:#FFDAA3FF'>质押平仓预警</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>数据服务</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>财务数据</li><li style='margin-right:6px;color:#FFDAA3FF'>市值数据</li><li style='margin-right:6px;color:#FFDAA3FF'>行情数据</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>公司洞察</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>企业全貌族谱实质关联</li><li style='margin-right:6px;color:#FFDAA3FF'>多节点关联</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>市场资讯</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>财经新闻</li><li style='margin-right:6px;color:#FFDAA3FF'>财经快讯</li></ul></div>",
            },
          ],
        },
        l: {
          name: "",
          children: [
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>日常治理</div><div style='display:flex;font-size:14px;flex-wrap:wrap'><span style='margin-right:6px;color:#FFDAA3FF'>董蜜日历</span><span style='margin-right:6px;color:#FFDAA3FF'>文件管理</span><span style='margin-right:6px;color:#FFDAA3FF'>法律法规</span><span style='margin-right:6px;color:#FFDAA3FF'>公告大全</span><span style='margin-right:6px;color:#FFDAA3FF'>事项管理</span><span style='margin-right:6px;color:#FFDAA3FF'>合规交易</span></div></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>信披管理</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>信披权限表</li><li style='margin-right:6px;color:#FFDAA3FF'>新披露清单</li><li style='margin-right:6px;color:#FFDAA3FF'>批复核</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>会议管理</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>会议清单</li><li style='margin-right:6px;color:#FFDAA3FF'>会议统计</li><li style='margin-right:6px;color:#FFDAA3FF'>议案检索</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>投资者关系</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>机构调研</li><li style='margin-right:6px;color:#FFDAA3FF'>互动问答</li><li style='margin-right:6px;color:#FFDAA3FF'>舆情监控</li><li style='margin-right:6px;color:#FFDAA3FF'>研报数据</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>监管信息</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>监管动态</li><li style='margin-right:6px;color:#FFDAA3FF'>违规案例</li><li style='margin-right:6px;color:#FFDAA3FF'>法律法规</li><li style='margin-right:6px;color:#FFDAA3FF'>监管问询</li></ul></div>",
            },
          ],
        },
      },
    };
  },
  methods: {
    async playPageStart() {
      await this.page16Fn();
      await sleep(7000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page16Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });

      this.title.runIn();
      await sleep(1000);
      this.$refs.twoflanks.init(this.treeData, {
        text: "",
        rootNodeLength: 400,
        width: 1200,
        height: 600,
        img,
        branchNodeWidth: 500,
        branchNodeHeight: 200,
        multiple: 4,
        lineLength: 300, // 线长度
        nodeSpaceBetween: 120, // 节点间宽度
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page16 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 172px;
  }
  > .content {
    position: absolute;
    top: 130px;
    left: 50px;
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
/deep/ .node-branch {
  padding: 15px 16px !important ;
  text-align: left;
  border: 1px solid #fe9805;
  border-radius: 12px !important;
  text-align: left !important;
  width: 380px;
  height: 80px;
}
/deep/ .node-branch-l {
  transform: translate(-50px, 20px);
}
/deep/ .node-branch-r {
  transform: translate(50px, 20px);
}
/deep/ .node-branch-0-l {
  height: 100px !important ;
}
/deep/ .main {
  width: 80% !important;
  height: 80% !important;
  transform: translate(50px, 50px);
}
</style>