<template>
    <div :id="id" class="textShade">
        <div :style="{transform: `translateY(${direction === 'topToBottom' ? '-' : ''}${textShadeHeight}px)`, transition: `${!textShadeHeight ? 'all 1s' : ''}`}">
            <slot name="shade"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id', 'autoPlay', 'direction'],
    data: function() {
        return {
            textShadeHeight: 0,
        }
    },
    mounted() {
        const textShadeHeight = document.getElementById(this.id).offsetHeight
        this.textShadeHeight = textShadeHeight
        if (this.autoPlay) {
            setTimeout(() => {
                this.play()
            }, 0)
        }
        
    },
    methods: {
        play() {
            this.textShadeHeight = 0
        },
    }
}
</script>
<style lang="less" scoped>
.textShade {
    width: 100%;
    overflow: hidden;
}
</style>
