<template>
  <div class="page9">
    <TextShade id="title2" ref="title2">
      <template v-slot:shade>
        <h2>行业概览</h2>
      </template>
    </TextShade>
    <div class="content">
      <div>
        <div class="vs_items">
          <div>
            <div style="background-color: #05c5ff" id="animat1" class="hidden1">
              投资者
            </div>
            <ul style="margin: 0 78px" id="animat2" class="hidden3">
              <li>年化增长8%~9%</li>
              <li>1.6亿</li>
            </ul>
            <ul id="animat3" class="hidden3">
              <li>辅助工具</li>
              <li>专业、智能</li>
            </ul>
          </div>
          <span id="animat4" class="hidden2"> VS </span>
          <div>
            <div style="background-color: #ff4215" id="animat5" class="hidden0">
              证券行业
            </div>
            <ul style="margin: 0 78px" id="animat6" class="hidden3">
              <li>证券行业营收</li>
              <li>4500亿</li>
            </ul>
            <ul id="animat7" class="hidden3">
              <li>IT开支（年化+10%）</li>
              <li>200亿+</li>
            </ul>
          </div>
        </div>
        <div class="card1 hidden1" id="animat8">
          <div>
            <p>行情交易、新闻公告</p>
            <p>1992-2010</p>
            <p>电脑、网络</p>
          </div>
          <p>大智慧、通达信 恒生、金证</p>
        </div>
        <div class="card2 hidden0" id="animat9">
          <div>
            <p>行情交易、新闻公告 数据整理、研究报告 代销产品、社区BBS</p>
            <p>2010-至今</p>
            <p>代销牌照、资料员 手机、电脑</p>
          </div>
          <p>同花顺、万德、东财</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page8Fn();
      await sleep(3000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page8Fn() {
      // this.title = anyElement({
      //   targets: document.querySelector("#title"),
      //   isSplitTxt: true,
      //   duration: 1000,
      //   delay: 200,
      //   easing: "easeOutQuint",
      //   inEffect: "fadeIn",
      //   outEffect: "bottomOut",
      // });
        await sleep(100);
        this.$refs.title2.play();
      await sleep(1000);
      $("#animat1").removeClass("hidden1");
      $("#animat4").removeClass("hidden2");
      $("#animat5").removeClass("hidden0");
      $("#animat2").removeClass("hidden3");
      $("#animat3").removeClass("hidden3");
      $("#animat6").removeClass("hidden3");
      $("#animat7").removeClass("hidden3");
      $("#animat8").removeClass("hidden1");
      $("#animat9").removeClass("hidden0");
      await sleep(2000);
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335")
    format("truetype");
}
.page9 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 100px;
  box-sizing: border-box;
  overflow: hidden;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 168px;
  }
  > .content {
    > div {
      display: flex;
      .vs_items {
        > div {
          display: flex;
          align-items: center;
          > div {
            width: 172px;
            height: 172px;
            border-radius: 50%;
            line-height: 172px;
            text-align: center;
            font-size: 32px;
            font-weight: 500;
            transition: all 0.7s;
          }
          > ul {
            transition: all 0.7s;
            > li {
              &:first-child {
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 10px;
              }
              &:last-child {
                font-size: 48px;
                font-weight: bold;
                color: #fe9805;
              }
            }
          }
        }
        > span {
          display: block;
          width: 64px;
          height: 64px;
          background: #fe9805;
          margin: 58px 0;
          line-height: 64px;
          text-align: center;
          font-size: 36px;
          color: #0f0d0e;
          margin-left: 86px;
          transform: translateX(-50%);
          font-weight: bold;
          border-radius: 4px;
          transition: all 2s;
        }
      }
      .card1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 40px;
        margin-top: 28px;
        transition: all 1s;
        > div {
          border: 1px solid #707070;
          border-radius: 12px;
          background: rgba(15, 13, 14, 0.39);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 54px;
          > p {
            font-size: 24px;
            font-weight: 400;
            &:nth-child(1) {
              margin: 72px 0;
              color: #fe9805;
              font-weight: bold;
            }
            &:nth-child(3) {
              margin: 110px 0 100px 0;
              color: #fe9805;
              font-weight: bold;
            }
          }
        }
        > p {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          width: 60%;
          margin-top: 24px;
        }
      }
      .card2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 72px;
        margin-top: 28px;
        transition: all 1s;
        > div {
          border: 1px solid #707070;
          border-radius: 12px;
          background: rgba(15, 13, 14, 0.39);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 54px;
          > p {
            font-size: 24px;
            font-weight: 400;
            &:nth-child(1) {
              margin: 32px 0 48px 0;
              color: #fe9805;
              font-weight: bold;
              max-width: 220px;
              text-align: center;
            }
            &:nth-child(3) {
              margin: 110px 0 68px 0;
              color: #fe9805;
              font-weight: bold;
              max-width: 200px;
              text-align: center;
            }
          }
        }
        > p {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          margin-top: 24px;
        }
      }
    }
  }
}
.hidden0 {
  opacity: 0 !important;
  transform: translateY(50px);
}
.hidden1 {
  opacity: 0 !important;
  transform: translateY(-50px);
}
.hidden2 {
  opacity: 0 !important;
}
.hidden3 {
  opacity: 0 !important;
  transform: translateX(50px);
}
</style>