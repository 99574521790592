<template>
  <div class="page26">
    <h2 id="title">团队介绍</h2>
    <TextShade id="desc" ref="desc">
      <template v-slot:shade>
        <p id="p1">
          团队人员100+，70%为技术人员，在大数据、人工智能、金融软件服务领域积累10年以上的丰富经验。
        </p>
      </template>
    </TextShade>
    <ul>
      <li class="hidden" id="animat1">
        <img src="./images/page26/组 3034.png" alt="" />
      </li>
      <li class="hidden" id="animat2">
        <img src="./images/page26/组 3035.png" alt="" />
      </li>
      <li class="hidden" id="animat3">
        <img src="./images/page26/组 3036.png" alt="" />
      </li>
      <li class="hidden" id="animat4">
        <img src="./images/page26/组 3037.png" alt="" />
      </li>
    </ul>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page26Fn();
      await sleep(3000);
      this.$emit("playEnd");
    },
    async page26Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      //   this.p1 = anyElement({
      //     targets: document.querySelector("#p1"),
      //     isSplitTxt: true,
      //     duration: 1000,
      //     delay: 60,
      //     easing: "easeOutQuint",
      //     inEffect: "fadeIn",
      //     outEffect: "bottomOut",
      //   });
      this.title.runIn();
      await sleep(1000);
      //   this.p1.runIn();
      this.$refs.desc.play();
      await sleep(1000);
      $("#animat1").removeClass("hidden");
      $("#animat1").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("#animat2").removeClass("hidden");
      $("#animat2").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("#animat3").removeClass("hidden");
      $("#animat3").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("#animat4").removeClass("hidden");
      $("#animat4").addClass("animate__animated animate__bounceInRight ");
      await sleep(3000);
    },
  },
};
</script>
<style lang="less" scoped>
.page26 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 80px;
  }
  p {
    font-size: 32px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 80px;
  }
  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > li {
      width: 374px;
    }
  }
  .hidden {
    opacity: 0 !important;
  }
}
</style>