<template>
    <div class="page5">
        <h2>项目概况</h2>
        <ul>
            <li>
                <div class="hidden" id="item5">
                    <h1>2019</h1>
                    <span>（业务线继续扩张）</span>
                </div>
                <ul>
                    <TextShade id="item5_li1" ref="item5_li1">
                        <template v-slot:shade>
                            <li>与华创证券达成战略合作，提供互联网证券业务解决方案</li>
                        </template>
                    </TextShade>
                    <TextShade id="item5_li2" ref="item5_li2">
                        <template v-slot:shade>
                            <li>与上海证券报达成战略合作，开展董秘SaaS业务</li>
                        </template>
                    </TextShade>
                    <TextShade id="item5_li3" ref="item5_li3">
                        <template v-slot:shade>
                            <li>实现营收6268万</li>
                        </template>
                    </TextShade>
                </ul>
            </li>
            <li>
                <div class="hidden" id="item6">
                    <h1>2020</h1>
                    <span>（C端&B端市占率稳步提升）</span>
                </div>
                <ul>
                    <TextShade id="item6_li1" ref="item6_li1">
                        <template v-slot:shade>
                        <li>C端用户超过1000万</li>
                        </template>
                    </TextShade>
                    <TextShade id="item6_li2" ref="item6_li2">
                        <template v-slot:shade>
                        <li>市场排名到前五</li>
                        </template>
                    </TextShade>
                    <TextShade id="item6_li3" ref="item6_li3">
                        <template v-slot:shade>
                        <li>服务券商超60家</li>
                        </template>
                    </TextShade>
                    <TextShade id="item6_li4" ref="item6_li4">
                        <template v-slot:shade>
                        <li>服务上市公司超500家</li>
                        </template>
                    </TextShade>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import TextShade from '@/components/animeTxt/TextShade.vue'
import $ from "jquery";
export default {
    data: function() {
        return {}
    },
    components: {
        TextShade
    },
    methods: {
        async playPageStart() {
            await this.page4Fn();
            await sleep(4000);
            this.$emit("playEnd");
        },
        async page4Fn() {
            await sleep(100);
            $("#item5").removeClass("hidden");
            await sleep(1000);
            this.$refs.item5_li1.play();
            await sleep(1000);
            this.$refs.item5_li2.play();
            await sleep(1000);
            this.$refs.item5_li3.play();
            await sleep(1500);
            $("#item6").removeClass("hidden");
            await sleep(1000);
            this.$refs.item6_li1.play();
            await sleep(1000);
            this.$refs.item6_li2.play();
            await sleep(1000);
            this.$refs.item6_li3.play();
            await sleep(1000);
            this.$refs.item6_li4.play();
            await sleep(1500);
        },
    },
}
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335") format("truetype");
}
.page5 {
    height: 100%;
    width: 100%;
    background-color: rgba(27, 11, 14, 0.61);
    padding: 64px 120px 100px;
    box-sizing: border-box;
    > h2 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 240px;
    }
    > ul {
        display: flex;
        // justify-content: space-between;
        // align-items: center;
        color: #FF9C48;
        > li {
            margin-right: 132px;
            > div {
                display: flex;
                align-items: center;
                margin-bottom: 32px;
                transition: all 1s;
                > h1 {
                    font-weight: 400;
                    font-size: 64px;
                    font-family: 'harmonyBold';
                }
                > span {
                    font-size: 24px;
                    font-weight: 400;
                }
            }
            > ul {
                li {
                   font-size: 24px;
                   font-weight: 400;
                   margin-bottom: 24px;
                   display: flex;
                   align-items: center;
                   &::before {
                        content: ' ';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: #FF9C48;
                        border-radius: 50%;
                        margin-right: 16px;
                   }
                }
            }
        }
    }
}
.hidden {
  opacity: 0 !important;
  transform: translateX(50px);
}
</style>