<template>
  <div class="pages">
    <component :is="currentView" ref="page" @playEnd="playNext()"></component>
  </div>
</template>
<script>
import { setRem, getUrlParams } from "@/utils/utils.js";
import ApiServe from "@/api/api.js";
import FontFaceObserver from "fontfaceobserver";
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import page11 from "./page11.vue";
import page12 from "./page12.vue";
import page13 from "./page13.vue";
import page14 from "./page14.vue";
import page15 from "./page15.vue";
import page16 from "./page16.vue";
import page17 from "./page17.vue";
import page18 from "./page18.vue";
import page19 from "./page19.vue";
import page20 from "./page20.vue";
import page21 from "./page21.vue";
import page22 from "./page22.vue";
import page23 from "./page23.vue";
import page24 from "./page24.vue";
import page25 from "./page25.vue";
import page26 from "./page26.vue";
import page27 from "./page27.vue";
import page28 from "./page28.vue";
import page29 from "./page29.vue";
import page30 from "./page30.vue";
import page31 from "./page31.vue";
export default {
  data: function () {
    return {
      currentIndex: 1,
    };
  },
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16,
    page17,
    page18,
    page19,
    page20,
    page21,
    page22,
    page23,
    page24,
    page25,
    page26,
    page27,
    page28,
    page29,
    page30,
    page31,
  },
  created() {
    this.currentIndex = this.$route.query.page || 1
  },
  computed: {
    currentView() {
      if (this.currentIndex == 1) {
        return page1;
      }
      if (this.currentIndex == 2) {
        return page2;
      }
      if (this.currentIndex == 3) {
        return page3;
      }
      if (this.currentIndex == 4) {
        return page4;
      }
       if (this.currentIndex == 5) {
        return page5;
      }
      if (this.currentIndex == 6) {
        return page6;
      }
      if (this.currentIndex == 7) {
        return page7;
      }
      if (this.currentIndex == 8) {
        return page8;
      }
      if (this.currentIndex == 9) {
        return page9;
      }
      if (this.currentIndex == 10) {
        return page10;
      }
      if (this.currentIndex == 11) {
        return page11;
      }
        if (this.currentIndex == 12) {
        return page12;
      }
      if (this.currentIndex == 13) {
        return page13;
      }
      if (this.currentIndex == 14) {
        return page14;
      }
      if (this.currentIndex == 15) {
        return page15;
      }
      if (this.currentIndex == 16) {
        return page16;
      }
      if (this.currentIndex == 17) {
        return page17;
      }
      if (this.currentIndex == 19) {
        return page19;
      }
      if (this.currentIndex == 18) {
        return page18;
      }
      if (this.currentIndex == 20) {
        return page20;
      }
      if (this.currentIndex == 21) {
        return page21;
      }
      if (this.currentIndex == 22) {
        return page22;
      }
      if (this.currentIndex == 23) {
        return page23;
      }
      if (this.currentIndex == 24) {
        return page24;
      }
       if (this.currentIndex == 25) {
        return page25;
      }
      if (this.currentIndex == 26) {
        return page26;
      }
      if (this.currentIndex == 27) {
        return page27;
      }
      if (this.currentIndex == 28) {
        return page28;
      }
      if (this.currentIndex == 29) {
        return page29;
      }
      if (this.currentIndex == 30) {
        return page30;
      }
       if (this.currentIndex == 31) {
        return page31;
      }
     return page31;
    }
  },
  methods: {
    // 从url上读取录屏所需参数
    getParams() {
      const urlParams = getUrlParams();
      this.id = urlParams.id;
      this.model_id = urlParams.model_id;
      this.params = {
        id: urlParams.id,
        ip: urlParams.ip,
        port: urlParams.port,
        offset_x: urlParams.offset_x,
        offset_y: urlParams.offset_y,
        width: urlParams.width,
        height: urlParams.height,
      };
      //如果发现指定封面时间参数，就增加此参数
      const url_extra_parmas = JSON.parse(
        decodeURIComponent(urlParams.url_extra_parmas)
      );
      if (url_extra_parmas.coverFrameSeconds) {
        this.params.coverFrameSeconds = url_extra_parmas.coverFrameSeconds;
      }
      this.newIpPort =
        urlParams.source == "ali"
          ? "https://recordvideoali.wedengta.com"
          : "https://recordvideo.wedengta.com";
    },
    // 开始录像
    startRecord() {
      ApiServe.recordStart(this.newIpPort, this.params)
        .then(({ code }) => {})
        .catch((err) => {
          ApiServe.recordRelease(this.newIpPort, this.params);
        });
    },
    // 结束录像
    async stopRecord() {
      await ApiServe.recordStop(this.newIpPort, this.params);
      await ApiServe.combineAudiosWithBgm(
        this.newIpPort,
        this.params.ip,
        this.params.port,
        {
          id: this.id,
          model_id: this.model_id,
          videoTotalTime: this.videoTotalTime,
          videmBgm: this.bgAudioSrc,
          audiosOrderList: this.audiosOrderList,
        }
      );
      ApiServe.recordRelease(this.newIpPort, this.params);
    },
    // 播放下一页
    playNext() {
      this.currentIndex++;
      this.$nextTick(() => {
        if (this.$refs.page.playPageStart) {
          this.$refs.page.playPageStart();
        }
      });
    },
  },
  async mounted() {
    //每个项目都可以独立设置
    setRem(1920);
    //等待字体，和所需的各种数据，音频的加载全部完成，才能开始播放
    const harmonyMidium = new FontFaceObserver("harmonyRegular");
    const harmonyBold = new FontFaceObserver("harmonyBold");
    await Promise.all([
      harmonyMidium.load(null, 30 * 1000),
      harmonyBold.load(null, 30 * 1000),
    ]);
    // 从第一个页面开始播放
    this.$refs.page.playPageStart();
    //开始录屏
    // this.startRecord();
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
