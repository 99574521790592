<template>
  <div class="page21">
    <h2 id="title">C端产品及服务—服务创造价值</h2>
    <div class="content">
      <TwoflanksTree ref="twoflanksTree" id="twoflanksTree" />
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import TwoflanksTree from "@/components/customTree/twoflanks.vue";
import img from "./images/page12/组 2935.svg";
import $ from "jquery";
export default {
  data: function () {
    return {
      data: {
        l: {
          name: "",
          children: [
            {
              name: "<h3>行业大数据</h3><ul><li><span>灯塔直播</span><span>智能盯盘</span><span>集合竞价分时</span></li><li><span>股市地图</span><span>大盘预警</span><span>财报对比</span><span>...</span></li></ul>",
            },
            {
              name: "<h3>智能资讯</h3><ul><li><span>热点发现</span><span>智能标注</span><span>关联推荐</span><span>公告解析</span></li><li><span>投资图谱</span><span>自选日报</span><span>投资日历</span><span>...</span></li></ul>",
            },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "<h3>选股工具</h3><ul><li><span>相似K线</span><span>形态选股</span><span>龙虎榜单</span><span>融资追击</span></li><li><span>定增掘金</span><span>智能诊股</span><span>智能选股</span><span>...</span></li></ul>",
            },
            {
              name: "<h3>择时工具</h3><ul><li><span>多空信号</span><span>筹码分布</span><span>沪深港通</span><span>股权质押</span></li><li><span>估值交易</span><span>历史回看</span><span>短线起爆</span><span>...</span></li></ul>",
            },
          ],
        },
      },
    };
  },
  components: {
    TwoflanksTree,
  },
  methods: {
    async playPageStart() {
      await this.page21Fn();
      await sleep(3000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page21Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.title.runIn();
      await sleep(2000);
      this.$refs.twoflanksTree.init(this.data, {
        width: 1100,
        height: 580,
        lineLength: 100,
        nodeSpaceBetween: 220,
        multiple: 2,
        branchNodeWidth: 640, // 分支宽度
        branchNodeHeight: 200, // 分支高度
        rootNodeLength: 200,
        img: img,
        bottomNode: "<h3>智能助手机器人</h3><div>灯塔表哥</div>",
      });
      await sleep(5000);
    },
  },
};
</script>
<style lang="less" scoped>
.page21 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
  }
  /deep/ .node-branch {
    padding: 15px 16px !important ;
    text-align: left;
    border-radius: 24px !important;
    text-align: left !important;
    width: 427px;
    height: 166px;
    > h3 {
      font-size: 32px !important;
      font-weight: bold;
      margin-bottom: 18px;
      margin-top: 8px;
    }
    > ul {
      > li {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
      }
    }
  }
  /deep/ .node-branch-l {
    background: url("./images/page21/组 3059.svg") no-repeat,
      linear-gradient(
        270deg,
        rgba(255, 163, 163, 0.16) 0%,
        rgba(255, 163, 163, 0) 100%
      ) !important;
    background-size: contain !important;
    transform: translateX(-230px);
    border: 2px dashed #ce3620 !important;
  }
  /deep/ .node-branch-r {
    background: url("./images/page21/组 3060.svg") no-repeat,
      linear-gradient(
        270deg,
        rgba(254, 152, 5, 0) 0%,
        rgba(254, 152, 5, 0.16) 100%
      ) !important;
    background-size: contain !important;
    transform: translateX(230px);
    border: 2px dashed #fe9805 !important;
  }
  /deep/ .node-branch-b {
    padding: 15px 16px !important ;
    border-radius: 24px !important;
    width: 338px;
    height: 96px;
    background: url("./images/page21/组 3061.svg") no-repeat,
      linear-gradient(
        270deg,
        rgba(255, 163, 163, 0.16) 0%,
        rgba(255, 163, 163, 0) 100%
      ) !important;
    background-size: contain !important;
    transform: translateY(36px);
    border: 2px dashed #f1efed !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff !important;
    > h3 {
      font-weight: bold;
      font-size: 32px !important;
    }
  }
}
</style>