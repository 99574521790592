<template>
  <div class="page24">
    <h2 id="title">产品技术优势</h2>
    <TwoflanksTree ref="twoflanks" id="twoflanks" />
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TwoflanksTree from "@/components/customTree/twoflanks.vue";
import img from "./images/circle24.png";
import nodeImg from "./images/nodeImg1.png";
export default {
  components: {
    TwoflanksTree,
  },
  data: function () {
    return {
      treeData: {
        r: {
          name: "",
          children: [
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:10px'>高并发系统架构能力</div><ul style='font-size:14px;'><li style='margin-right:6px;margin-bottom:20px;color:#FFDAA3FF'>系统高并发、低延时；多线路接入、主备灵活切换；多 层容灾，实时检测，在线分钟级扩容，节省硬件和网络 带宽等资源 </li><li style='margin-right:6px;color:#FFDAA3FF'>系统框架独立性、健壮性和扩展性好，配置灵活、维护 便捷</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:10px'>企业级产品极致体验打造</div><ul style='font-size:14px;color:#FFE3BAFFF'><li style='margin-right:6px;margin-bottom:20px;color:#FFDAA3FF'>根植于亿万级用户产品研发经验，更深刻理解用户需求 和极致体验打造 </li><li style='margin-right:6px;color:#FFDAA3FF'>产品及服务行业解决方案完整，数百家行业客户认可， 工程实战经验丰富</li></ul></div>",
            },
          ],
        },
        l: {
          name: "",
          children: [
           {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:10px'>自然语义处理技术及AI机器学习</div><ul style='font-size:14px'><li style='margin-right:6px;margin-bottom:20px;color:#FFDAA3FF'>智能机器人：基于千万级别用户行为数据学习，提供 数据、行情、资讯等智能助手服务 </li><li style='margin-right:6px;margin-bottom:20px;color:#FFDAA3FF'>智能资讯处理：海量内容的文本分析、语义理解，秒 级发现热点、事件挖掘及舆情态度</li><li style='margin-right:6px;color:#FFDAA3FF'>金融知识图谱：证券行业高管、股权、产业链等图谱 产品，构建金融大脑</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:10px'>高性能大数据实时处理能力</div><ul style='font-size:14px'><li style='margin-right:6px;margin-bottom:20px;color:#FFDAA3FF'>海量资讯及用户行为数据分析，数百个维度标签，实 现“千人千面”个性化推荐服务 </li><li style='margin-right:6px;color:#FFDAA3FF'>非结构化文本解析及多媒体内容处理能力，一站式内容 智能创作服务</li></ul></div>",
            },
          ],
        },
      },
    };
  },
  methods: {
    async playPageStart() {
      await this.page24Fn();
         await sleep(7000);
      this.$emit("playEnd");
    },
    async page24Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.title.runIn();
      await sleep(1000);
      this.$refs.twoflanks.init(this.treeData, {
        text: "",
        rootNodeLength: 400,
        width: 1200,
        height: 600,
        img,
        branchNodeWidth: 600,
        branchNodeHeight: 600,
        multiple: 4,
        lineLength: 300, // 线长度
        nodeSpaceBetween: 260, // 节点间宽度
        nodeImg
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page24 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 240px;
  }
}
#twoflanks {
  position: absolute;
  top: 130px;
  left: 50px;
}
/deep/ .node-branch {
  padding: 15px 16px !important ;
  text-align: left;
  border: 1px solid #fe9805;
  border-radius: 12px !important;
  text-align: left !important;
  width: 380px;
  height: 80px;
}
/deep/ .node-branch-l {
  transform: translate(-50px, 20px);
}
/deep/ .node-branch-r {
  transform: translate(50px, 20px);
}
/deep/ .node-branch-0-r {
  height: 300px !important;
}
/deep/ .node-branch-1-r{
  height: 230px !important;
}
/deep/ .node-branch-0-l{
  height: 400px !important;
  width: 400px;
}
/deep/ .node-branch-1-l{
  height: 240px !important;
}

/deep/ .main {
  width: 80% !important;
  height: 80% !important;
  transform: translate(50px, 50px);
}
/deep/ .nodeImg{
  position: absolute;
  top: 20px;
  left: 20px;
  height: 320px;
  width: 400px;
}
</style>