<template>
  <div class="page23">
    <h2 id="title">C端产品及服务</h2>
    <TextShade id="p1" ref="p1">
      <template v-slot:shade>
        <p>
          互联网证券服务解决方案：与中小券商战略合作，优势互补，为券商打造一站式互联网证券服务平台。
        </p>
      </template>
    </TextShade>
    <video
      src="/videos/videoP23.MP4"
      muted
      ref="Video"
    ></video>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page23Fn();
      await sleep(3000);
      this.$emit("playEnd");
    },
    async page23Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.title.runIn();
      await sleep(1000);
      this.$refs.p1.play();
      await sleep(1000);
        this.$refs.Video.play();
      await sleep(4000);
    },
  },
};
</script>
<style lang="less" scoped>
.page23 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow: hidden;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 80px;
  }
  p {
    font-size: 32px;
    font-weight: bold;
    color: #fe9805;
    margin-bottom: 68px;
  }
}
video {
  mix-blend-mode: screen;
  height: 1100px;
  width: auto;
  transform: translate(-100px,-250px);
}
</style>