<template>
  <div class="page6">
    <TextShade id="title" autoPlay="true">
      <template v-slot:shade>
        <h2>灯塔的专利软著及行业资质情况</h2>
      </template>
    </TextShade>
    <div class="content">
      <ul>
        <li class="hidden" id="des1">
          <h4>专利</h4>
          <span><span id="num1">34</span>项</span>
        </li>
        <li class="hidden" id="des2">
          <h4>软著</h4>
          <span><span id="num2">45</span>项</span>
        </li>
      </ul>
      <h4 class="hidden" id="des3">2018年</h4>

      <TextShade id="item1" ref="item1">
        <template v-slot:shade>
          <p>“灯塔财经成为全国金融标准技术委员会信息披露专业工作组成员”</p>
        </template>
      </TextShade>
      <h4 class="hidden" id="des4">2021年</h4>

      <TextShade id="item2" ref="item2">
        <template v-slot:shade>
          <p>“灯塔财经成为中国证监会证券信息技术系统服务备案机构”</p>
        </template>
      </TextShade>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import TextShade from "@/components/animeTxt/TextShade.vue";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page6Fn();
       await sleep(3000);
      this.$emit("playEnd");
    },
    async page6Fn() {
      this.num1 = anyElement({
        targets: document.querySelector("#num1"),
        duration: 1000,
        toFixed: 0,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.num2 = anyElement({
        targets: document.querySelector("#num2"),
        duration: 1000,
        toFixed: 0,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      await sleep(1000);
      $("#des1, #des2").removeClass("hidden");
      this.num1.goIn.restart();
      this.num2.goIn.restart();
      await sleep(1000);
      $("#des3").removeClass("hidden");
      await sleep(1000);
      this.$refs.item1.play();
      await sleep(1000);
      $("#des4").removeClass("hidden");
      await sleep(1000);
      this.$refs.item2.play();
      await sleep(2000);
    },
  },
};
</script>
<style lang="less" scoped>
.page6 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow: hidden;
  h2 {
    font-size: 48px;
    font-weight: bold;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 180px;
    > ul {
      display: flex;
      align-items: center;
      margin-bottom: 78px;
      > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 1s;
        &:first-child {
          margin-right: 146px;
        }
        > h4 {
          font-size: 32px;
          font-weight: 400;
        }
        > span {
          font-size: 72px;
          color: #fe9805;
          font-weight: bold;
        }
      }
    }
    > h4 {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 22px;
      transition: all 1s;
    }
    #des4 {
      margin-top: 78px;
    }
    p {
      font-weight: bold;
      font-size: 48px;
      color: #fe9805;
      text-align: center;
    }
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
</style>
