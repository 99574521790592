<template>
    <div class="page25">
        <video
        src="/videos/team.MP4"
        muted
        ref="Video"
        ></video>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
export default {
    methods: {
        async playPageStart() {
            await this.page25Fn();
            this.$emit("playEnd");
        },
        async page25Fn() {
            this.$refs.Video.play();
            await sleep(1000);
            this.$refs.Video.pause();
            await sleep(1000);
            this.$refs.Video.play();
            await sleep(2000);
        },
    },
}
</script>
<style lang="less" scoped>
.page25 {
    width: 100%;
    height: 100%;
    video {
        width: 100%;
    }
}
</style>
