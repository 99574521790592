<template>
  <div class="page15">
    <div class="content">
       <TextShade id="title" ref="title">
      <template v-slot:shade>
        <div class="title">B端产品服务部分标杆客户</div>
      </template>
    </TextShade>
      <div class="marquee hidden" id="myMarquee1">
        <div class="marquee-inner">
          <div class="marquee-content">
            <span>
              <img src="./images/logos/1.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/2.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/3.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/4.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/5.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/6.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/7.png" alt="" />
            </span>
          </div>
        </div>
      </div>
      <div class="marquee hidden" id="myMarquee2">
        <div class="marquee-inner">
          <div class="marquee-content">
            <span>
              <img src="./images/logos/8.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/9.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/10.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/11.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/12.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/13.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/14.png" alt="" />
            </span>
          </div>
        </div>
      </div>
      <div class="marquee hidden" id="myMarquee3">
        <div class="marquee-inner">
          <div class="marquee-content">
            <span>
              <img src="./images/logos/15.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/16.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/17.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/18.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/19.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/20.png" alt="" />
            </span>
            <span>
              <img src="./images/logos/21.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import InfiniteMarquee from "infinite-marquee";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components:{
TextShade
  },
  methods: {
    async playPageStart() {
      await sleep(1000);
      await this.page15Fn();
      await sleep(7000);
      this.$emit("playEnd");
    },
    async page15Fn() {
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee1"),
        direction: "right",
        duration: 100,
        css: true,
      });
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee2"),
        direction: "left",
        duration: 100,
        css: true,
      });
      new InfiniteMarquee({
        el: document.querySelector("#myMarquee3"),
        direction: "right",
        duration: 100,
        css: true,
      });
      // this.title = anyElement({
      //   targets: document.querySelector("#title"),
      //   isSplitTxt: true,
      //   duration: 1000,
      //   delay: 100,
      //   easing: "easeOutQuint",
      //   inEffect: "fadeIn",
      //   outEffect: "bottomOut",
      // });
      this.$refs.title.play();
      await sleep(1000);
      $("#myMarquee1").removeClass("hidden");
      $("#myMarquee1").addClass("animate__animated animate__bounceInLeft ");
      await sleep(1000);
      $("#myMarquee2").removeClass("hidden");
      $("#myMarquee2").addClass("animate__animated animate__bounceInLeft ");
      await sleep(1000);
      $("#myMarquee3").removeClass("hidden");
      $("#myMarquee3").addClass("animate__animated animate__bounceInLeft ");
    },
  },
};
</script>
<style lang="less" scoped>
@import "infinite-marquee/assets/css/infinite-marquee";
.page15 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px;
  box-sizing: border-box;
  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 130px;
  }
  .hidden {
    opacity: 0;
  }
  .marquee {
    transition: all 1s;
  }
  .marquee-content {
    span {
      display: inline-block;
      margin-right: 48px;
      margin-bottom: 40px;
    }
  }
}
.animate__animated.animate__bounceInLeft {
  --animate-duration: 1s;
  // --animate-delay: 2s;
}
</style>