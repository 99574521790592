<template>
    <div class="page28">
       <video
        src="/videos/financial.MP4"
        muted
        ref="Video"
        ></video>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";

export default {
    methods: {
        async playPageStart() {
            await this.page28Fn();
            this.$emit("playEnd");
        },
        async page28Fn() {
            this.$refs.Video.play();
            await sleep(1000);
            this.$refs.Video.pause();
            await sleep(1000);
            this.$refs.Video.play();
            await sleep(2000);
        },
    },
}
</script>
<style lang="less" scoped>
.page28 {
    width: 100%;
    height: 100%;
    video {
        width: 100%;
    }
}
</style>
