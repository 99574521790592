<template>
  <div class="page27">
    <h2 id="title">股权结构</h2>
    <TwoflanksTree ref="twoflanks" id="twoflanks" />
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TwoflanksTree from "@/components/customTree/twoflanks.vue";
import img from "./images/circle27.png";
export default {
  components: {
    TwoflanksTree,
  },
  data: function () {
    return {
      treeData: {
        r: {
          name: "",
          children: [
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>3.2867%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>上海隋硕创业投资合伙企业</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>3.2867%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>湖北楚天高速投资有限责任公司</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>6.1270%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>上海淳喆股权投资基金合伙企业</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>3.9764%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>上海金昌投资控股有限公司</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>1.8259%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>日照财经国际财富中心有限公司</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>3.2258%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>山南置立方投资管理有限公司</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>2%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>厦门咏诚开润股权投资合伙企业</li></ul></div>",
            },
          ],
        },
        l: {
          name: "",
          children: [
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>26.9434%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>朱留峰</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>11.0943%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>荣强</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>13.7211%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>可克达拉市灯塔之心技术服务合伙企业</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>19.0349%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>上海统擎投资管理合伙企业</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>2.7389%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>杭州凯泰成德投资合伙企业</li></ul></div>",
            },
            {
              name: "<div><div style='color:#FE9805FF;font-size:20px;margin-bottom:5px'>2.7389%</div><ul style='display:flex;font-size:14px'><li style='margin-right:6px;color:#FFDAA3FF'>杭州凯泰创裕投资合伙企业</li></ul></div>",
            },
          ],
        },
      },
    };
  },
  methods: {
    async playPageStart() {
      await this.page27Fn();
         await sleep(7000);
      this.$emit("playEnd");
    },
    async page27Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.title.runIn();
      await sleep(1000);
      this.$refs.twoflanks.init(this.treeData, {
        text: "",
        rootNodeLength: 400,
        width: 1200,
        height: 1000,
        img,
        branchNodeWidth: 500,
        branchNodeHeight: 200,
        multiple: 4,
        lineLength : 300, // 线长度
        nodeSpaceBetween : 90, // 节点间宽度
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page27 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow-y: hidden;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 240px;
  }
}
#twoflanks {
  position: absolute;
  top: -200px;
  left: 50px;
}
/deep/ .node-branch {
  padding: 15px 16px !important ;
  text-align: left;
  border: 1px solid #fe9805;
  border-radius: 12px !important;
  text-align: left !important;
  width: 380px;
  height: 80px;
}
/deep/ .node-branch-l {
  transform: translate(-50px, 20px);
}
/deep/ .node-branch-r {
  transform: translate(50px, 10px);
}
/deep/ .main {
  width: 80% !important;
  height: 80% !important;
  transform: translate(50px, 50px);
}
</style>