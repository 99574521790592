<template>
  <div class="page10">
        <h2>行业概览</h2>
    <div class="content">
      <h1 id="animat1">痛点！效率低</h1>
      <div>
        <ul>
          <li id="animat2">资讯信息（时效性？）</li>
          <li id="animat3">数据搜集（效率？）</li>
          <li id="animat4">数据使用（工具？）</li>
          <li id="animat5">研究报告（信息量大，关联性？）</li>
          <li id="animat6">跨品种交易（割裂？）</li>
        </ul>
        <div class="arrow hidden2" id="animat7">
          <span></span>
          <svg width="32" height="32" viewBox="-50 -50 300 300">
            <polygon
              class="triangle"
              stroke-linejoin="round"
              points="0 0, 160 100,0 200"
            />
          </svg>
        </div>
        <ul>
          <li id="animat8">机器学习</li>
          <li id="animat9">自然语言理解</li>
          <li id="animat10">人工智能</li>
        </ul>
        <div class="arrow hidden2" id="animat11">
          <span></span>
          <svg width="32" height="32" viewBox="-50 -50 300 300">
            <polygon
              class="triangle"
              stroke-linejoin="round"
              points="0 0, 160 100,0 200"
            />
          </svg>
        </div>
        <div class="question_mark hidden1" id="animat12">
          <span id="animat13" class="hidden0">?</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
export default {
  data: function () {
    return {};
  },
  methods: {
    async playPageStart() {
      await this.page9Fn();
      await sleep(3000);
      this.$emit("playEnd");
    },
    async page9Fn() {
      // this.title = anyElement({
      //     targets: document.querySelector("#title"),
      //     isSplitTxt: true,
      //     duration: 1000,
      //     delay: 200,
      //     easing: "easeOutQuint",
      //     inEffect: "fadeIn",
      //     outEffect: "bottomOut",
      // });

      this.animat1 = anyElement({
        targets: document.querySelector("#animat1"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat2 = anyElement({
        targets: document.querySelector("#animat2"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat3 = anyElement({
        targets: document.querySelector("#animat3"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat4 = anyElement({
        targets: document.querySelector("#animat4"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat5 = anyElement({
        targets: document.querySelector("#animat5"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat6 = anyElement({
        targets: document.querySelector("#animat6"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat8 = anyElement({
        targets: document.querySelector("#animat8"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat9 = anyElement({
        targets: document.querySelector("#animat9"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.animat10 = anyElement({
        targets: document.querySelector("#animat10"),
        isSplitTxt: true,
        duration: 1000,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      // this.title.runIn();
      this.animat1.runIn();
      await sleep(1000);
      this.animat2.runIn();
      await sleep(1000);
      this.animat3.runIn();
      await sleep(1000);
      this.animat4.runIn();
      await sleep(1000);
      this.animat5.runIn();
      await sleep(1600);
      this.animat6.runIn();
      await sleep(1000);
      $("#animat7").removeClass("hidden2");
      await sleep(1000);
      this.animat8.runIn();
      await sleep(1000);
      this.animat9.runIn();
      await sleep(1000);
      this.animat10.runIn();
      await sleep(1000);
      $("#animat11").removeClass("hidden2");
      await sleep(1000);
      $("#animat12").removeClass("hidden1");
      $("#animat13").removeClass("hidden0");
      await sleep(2000);
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335")
    format("truetype");
}
.page10 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 100px;
  box-sizing: border-box;
  overflow: hidden;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 128px;
  }
  .content {
    margin-left: 214px;
    > h1 {
      color: #ff8331;
      font-size: 100px;
      font-family: "harmonyBold";
      margin-bottom: 90px;
    }
    > div {
      display: flex;
      align-items: center;
      > ul {
        > li {
          color: #fe9805;
          font-size: 32px;
          margin-bottom: 36px;
          font-weight: bold;
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        margin: 0 48px;
        transition: all 0.7s;
        > span {
          width: 44px;
          height: 12px;
          background: #fe9805;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        > svg {
          margin-left: -6px;
        }
        .triangle {
          fill: #fe9805;
          stroke: #fe9805;
          stroke-width: 60;
        }
      }
      .question_mark {
        width: 258px;
        height: 258px;
        border: 12px solid #fe9805;
        border-radius: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.7s;
        > span {
          transition: all 0.7s;
          font-size: 200px;
          color: #ff6d00;
          font-weight: bold;
        }
      }
    }
  }
}
.hidden0 {
  opacity: 0 !important;
  transform: translateY(50px);
}
.hidden1 {
  opacity: 0 !important;
}
.hidden2 {
  opacity: 0 !important;
  transform: translateX(-50px);
}
</style>