<template>
    <div class="page2">
        <div class="bg entrance" id="bg"></div>
        <div class="content">
            <TextShade id="catalogue" ref="catalogue">
                <template v-slot:shade>
                    <h1>目录</h1>
                </template>
            </TextShade>
            <TextShade id="title" ref="title">
                <template v-slot:shade>
                    <p>01. 项目概况及投资亮点</p>
                    <p>02. 行业概览</p>
                    <p>03. 业务介绍</p>
                    <p>04. 团队介绍</p>
                    <p>05. 财务现状与预测</p>
                </template>
            </TextShade>
        </div>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import TextShade from '@/components/animeTxt/TextShade.vue'
import $ from "jquery";

export default {
    components: {
        TextShade
    },
    methods: {
        async playPageStart() {
            await this.page1Fn();
            this.$emit("playEnd");
        },
        async page1Fn() {
            $("#bg").removeClass("entrance");
            await sleep(500);
            this.$refs.catalogue.play();
            await sleep(1000);
            this.$refs.title.play();
            await sleep(2000);
        },
    },
}
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335") format("truetype");
}
.page2 {
    width: 100%;
    height: 100%;
    .bg {
        position: absolute;
        width: 64%;
        margin-left: 36%;
        border-left-width: 16vw;  /*有斜边的方向，数值的大小决定斜边的梯度率*/
        border-bottom-width: 100vh; /*梯形的高*/
        border-style: solid;
        border-color: transparent transparent rgba(27, 11, 14, 0.82) transparent; /*选择显示哪个方向的梯形*/
        z-index: 1;
        box-sizing: border-box;
        transition: all 1s;
    }
    .content {
        position: absolute;
        margin-left: 60%;
        margin-top: 8%;
        z-index: 2;
        h1 {
            font-size: 100px;
            color: #FF8331;
            letter-spacing: 20px;
            font-weight: 400;
            font-family: 'harmonyBold';
            margin-bottom: 44px;
        }
         p {
            font-size: 48px;
            color: #FFFFFF;
            font-weight: 500;
            margin-bottom: 48px;
        }
    }
}
.entrance {
    transform: translateX(70vw);
    opacity: 0 !important;
}
</style>
