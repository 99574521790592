<template>
  <div class="page13">
    <TextShade id="title2" ref="title2">
      <template v-slot:shade>
        <h2>B端产品及服务</h2>
      </template>
    </TextShade>

    <CustomTree ref="customTree" id="customTree1" />
    <TextShade id="animat1" ref="animat1">
      <template v-slot:shade>
            <h3>灯塔服务B端客户的优势</h3>
      </template>
    </TextShade>
    <ul id="animat2" class="hidden1">
      <TextShade id="animat3" ref="animat3">
        <template v-slot:shade>
              <li>
                ·C端流量和用户运营的丰富经验带来对客户需求更深刻的认知
              </li>
        </template>
      </TextShade>
      <TextShade id="animat4" ref="animat4">
        <template v-slot:shade>
              <li>
                ·产品的创新和迭代速度位于行业前列（如相似K线等产品是灯塔在业内首创）
              </li>
        </template>
      </TextShade>
      <TextShade id="animat5" ref="animat5">
        <template v-slot:shade>
              <li>
                ·可提供标准化产品、整体解决方案、微服务框架等多种形式
              </li>
        </template>
      </TextShade>
    </ul>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import CustomTree from "@/components/customTree";
import img from "./images/page13/组 2935.svg";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {
      data: {
        name: "",
        children: [
          {
            name: '业务前台',
            children: [{
              name: "投研+投顾系统；量化系统；营销系 统咨询、知识图谱、算法",
              children: [{ 
                name: "智能决策工具+智能投资助手"
              }],
            }],
          },
          {
            name: '业务中台',
            children: [{
              name: "弹指文、一键分发、互联网运营服务",
              children: [{ name: "智能内容创作+分发+互联网运营服务" }],
            }]
          },
          {
            name: '业务后台',
            children: [{
              name: "智能化平台架构及搭建",
              children: [{ name: "证券服务化平台+大数据平台+行情云服务" }],
            }],
          },
        ],
      },
    };
  },
  components: {
    CustomTree,
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page13Fn();
      await sleep(3000);
      this.$emit("playEnd");
    },
    async page13Fn() {
      // await sleep(100);
      this.$refs.title2.play();
      await sleep(1000);
      this.$refs.customTree.CustomTreeChart(this.data, {
        marginTop: 60,
        marginLeft: 0,
        marginBottom: 30,
        height: 360,
        width: "100%",
        img,
        nodeLength: 180,
        lineWidth: 260,
        branchNodeWidth: 220,
        multiple: 1.18,
      });
      await sleep(1500);
      this.$refs.animat1.play()
      await sleep(500);
      $("#animat2").removeClass("hidden1");
      await sleep(1000);
      this.$refs.animat3.play()
      await sleep(1000);
      this.$refs.animat4.play()
      await sleep(1000);
      this.$refs.animat5.play()
      await sleep(3000);
    },
  },
};
</script>
<style lang="less" scoped>
.page13 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  h2 {
    font-size: 48px;
    font-weight: bold;
  }
  h3 {
    font-size: 32px;
    font-weight: bold;
    color: #fe9805;
    margin-top: 44px;
    transition: all 0.7s;
  }
  > ul {
    margin-top: 24px;
    height: 154px;
    border: 1px solid #fe9805;
    background: linear-gradient(
      180deg,
      rgba(63, 35, 11, 0) 0%,
      rgba(126, 108, 92, 0) 100%
    );
    border-radius: 24px;
    padding: 16px 24px;
    transition: all 1s;
    li {
      color: #fe9805;
      font-weight: 400;
      font-size: 32px;
      padding: 4px 0;
      &:nth-child(2) {
        margin: 24px 0;
      }
    }
  }
  .hidden {
    opacity: 0 !important;
  }
  .hidden1 {
    opacity: 0 !important;
    transform: translateY(50px);
  }
}
</style>