<template>
  <div class="page29">
    <h2 id="title">财务现状与预测</h2>
    <div class="content">
      <TextShade id="p1" ref="p1">
        <template v-slot:shade>
          <p>业绩高速增长，自2018年起实现大规模稳定盈利</p>
        </template>
      </TextShade>
      <div>
        <div class="hidden" id="animat1">
          <h3>过去三年财务数据</h3>
          <BarStacked id="bar" ref="bar" />
        </div>
        <div class="hidden" id="animat2">
          <h3>2020年营收组成</h3>
          <div id="pie"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import BarStacked from "@/components/barStacked";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {
      data: [
        {
          label: "2018",
          value1: 2000,
          value2: 2171,
          des: "净利润2072w",
        },
        {
          label: "2019",
          value1: 3000,
          value2: 3268,
          des: "净利润2022w",
        },
        {
          label: "2020",
          value1: 4000,
          value2: 4542,
          des: "净利润2576w",
        },
      ],
    };
  },
  components: {
    BarStacked,
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page29Fn();
       await sleep(3000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page29Fn() {
      this.title = anyElement({
        targets: document.querySelector("#title"),
        isSplitTxt: true,
        duration: 1000,
        delay: 200,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.title.runIn();
      await sleep(2000);
      this.$refs.p1.play();
      await sleep(2000);
      $("#animat1").removeClass("hidden");
      this.initBar();
      await sleep(1000);
      $("#animat2").removeClass("hidden");
      this.initPie();
      await sleep(3000);
    },
    initBar() {
      this.$refs.bar.StackedBarChart(this.data, {
        height: 360,
        width: 465,
        numberColor: "#fff",
        numberSuffix: "w",
        isPopOverVisible: true,
      });
    },
    initPie() {
      const myPie = this.$echarts.init(document.getElementById("pie"));
      const option = {
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            itemStyle: {
              borderColor: "rgba(15, 13, 14, 0.39)",
              borderWidth: 2,
            },
            color: ["#FFFFFF", "#FE9805", "#FF4805"],
            label: {
              alignTo: "edge",
              formatter: function (params) {
                if (params.value === 4513) {
                  return `{value|C端：${params.value}w}\n{name|${params.name}}`;
                }
                if (params.value === 1812) {
                  return `{value|B端：${params.value}w}\n{name|${params.name}}`;
                }
                if (params.value === 2217) {
                  return `{value|B端：${params.value}w}\n{name|${params.name}}`;
                }
              },
              edgeDistance: 10,
              color: "#fff",
              fontSize: 12,
              rich: {
                name: {
                  lineHeight: 18,
                },
                value: {
                  lineHeight: 24,
                },
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80,
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myPie.getWidth() / 2;
              const points = params.labelLinePoints;
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: [
              { name: "董蜜SaaS平台", value: 1812 },
              { name: "金融行业服务解决方案", value: 2217 },
              { name: "互联网证券信息服务", value: 4513 },
            ],
          },
        ],
      };
      myPie.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.page29 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 80px;
  }
  > .content {
    p {
      font-size: 32px;
      color: #ffffff;
      font-weight: 400;
      margin-bottom: 36px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        background: rgba(15, 13, 14, 0.39);
        border: 1px solid #ffbe60;
        border-radius: 32px;
        height: 640px;
        width: 46%;
        padding: 36px;
        box-sizing: border-box;
        transition: all 0.7s;
        > h3 {
          font-size: 32px;
          font-weight: 400;
          margin-bottom: 48px;
        }
        > div {
          height: 472px;
        }
      }
    }
  }

  .hidden {
    opacity: 0 !important;
    transform: translateY(50px);
  }
}
</style>
