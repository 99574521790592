<template>
  <div class="page20">
    <h2 id="title">B端产品及服务</h2>
     <TextShade id="desc" ref="desc">
        <template v-slot:shade>
           <span id="p1" style="marginbottom: 36px">
      其他产业互联网数字化转型解决方案：公司在智慧城市及建筑行业数字化转型领域，通过云计算、大数据、人工智
      能等核心技术，与中信工程、腾讯等合作伙伴联合打造智慧城市数字基础设施，促进数字化技术与工程建设行业的
      融合发展，为产业 数字化转型提供智能化解决方案。
    </span>
    <span style="color: #fe9805" id="p2">
      典型案例：与中信工程合作打造面向建筑行业的智能建造平台。
    </span>
        </template>
      </TextShade>
   
    <div class="img_1 hidden">
      <img src="../dengtaBP/images/page20/1.png" alt="" />
    </div>
    <div class="img_2 hidden">
      <img src="../dengtaBP/images/page20/2.png" alt="" />
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
   components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page20Fn();
       await sleep(7000);
      this.$emit("playEnd");
    },
    async page20Fn() {
        await sleep(100);
      this.$refs.desc.play();
      await sleep(1000);
      $(".img_2").removeClass("hidden");
      $(".img_2").addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $(".img_1").removeClass("hidden");
      $(".img_1").addClass("animate__animated animate__bounceInLeft");
    },
  },
};
</script>
<style lang="less" scoped>
.page20 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 64px;
  }
  span {
    font-size: 32px;
    font-weight: 400;
    line-height: 58px;
  }
  .img_1 {
    width: 1096px;
    position: absolute;
    left: 82px;
    top: 397px;
    z-index: 999;
  }
  .img_2 {
    width: 1120px;
    position: absolute;
    right: 83px;
    bottom: 88px;
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
</style>