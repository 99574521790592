<template>
  <div class="page12">
    <TextShade id="title2" ref="title2">
      <template v-slot:shade>
        <h2>业务体系</h2>
      </template>
    </TextShade>
    <div class="content">
      <CustomTree ref="customTree" id="customTree" />
      <div class="text textAnimat" id="text">
        <span>证券服务</span>
        <span>投教服务</span>
      </div>
    </div>
    <div class="bottom">
      <TwoflanksTree ref="twoflanksTree1" id="twoflanksTree1" />
      <TwoflanksTree ref="twoflanksTree2" id="twoflanksTree2" />
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import $ from "jquery";
import CustomTree from "@/components/customTree";
import TwoflanksTree from "@/components/customTree/twoflanks.vue";
import img1 from "./images/page12/组 2935.svg";
import img2 from "./images/page12/组 2941.svg";
import img3 from "./images/page12/组 2941(1).svg";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {
      data: {
        name: "",
        children: [
          {
            name: '创新IT服务',
            children: [
              {
                name: '上市公司、监管机构'
              }
            ],
          },
          {
            name: '创新合作',
            children: [
              {
                name: "华创、华鑫、万联、益学堂",
                children: [
                  {
                    name: "投资者",
                  },
                ],
              },
            ],
          },
          {
            name: '合规信息化',
            children: [
              {
                name: '券商、银行、政府'
              }
            ],
          },
        ],
      },
      data1: {
        l: {
          name: "",
          children: [
            {
              name: "智能机器人",
            },
            {
              name: "智能行情&资讯",
            },
            {
              name: "智能分析引擎",
            },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "大数据平台",
            },
            {
              name: "合规管理",
            },
            {
              name: "运营分发等",
            },
          ],
        },
      },
      data2: {
        l: {
          name: "",
          children: [
            {
              name: "机器学习",
            },
            {
              name: "自然语义理解",
            },
            {
              name: "大数据分析",
            },
          ],
        },
        r: {
          name: "",
          children: [
            {
              name: "营销统计",
            },
            {
              name: "区块链",
            },
            {
              name: "边缘计算",
            },
          ],
        },
      },
    };
  },
  components: {
    CustomTree,
    TwoflanksTree,
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page12Fn();
      this.$emit("playEnd");
    },
    async page12Fn() {
      await sleep(100);
      this.$refs.title2.play();
      await sleep(1000);
      this.$refs.customTree.CustomTreeChart(this.data, {
        marginTop: 10,
        marginLeft: 0,
        marginBottom: 10,
        height: 220,
        width: "100%",
        img: img1,
        nodeLength: 180,
        branchNodeWidth: 250,
        branchNodeHeight: 50,
        lineWidth: 280,
        multiple: 1.18,
      });
      await sleep(1400);
      $('#text').removeClass('textAnimat')
      await sleep(1000);
      this.$refs.twoflanksTree1.init(this.data1, {
        width: 580,
        height: 300,
        lineLength: 150,
        nodeSpaceBetween: 80,
        multiple: 4,
        rootNodeLength: 150,
        img: img2,
      });
      this.$refs.twoflanksTree2.init(this.data2, {
        width: 580,
        height: 300,
        lineLength: 150,
        nodeSpaceBetween: 80,
        multiple: 4,
        rootNodeLength: 150,
        img: img3,
      });
      await sleep(6000);
    },
  },
};
</script>
<style lang="less" scoped>
.page12 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  h2 {
    font-size: 48px;
    font-weight: bold;
  }
  .content {
    position: relative;
    margin-top: 108px;
    .text {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 134px;
      right: 300px;
      font-size: 20px;
      color: #FE9805;
      transition: all 0.7s;
      > span {
        padding: 2px;
      }
    }
    .textAnimat {
       transform: scale(1.5);
       opacity: 0;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
  }
}
</style>