<template>
  <div class="page4" id="page4">
    <h2>项目概况</h2>
    <div class="content">
      <ul>
        <li>
          <div class="hidden" id="item1">
            <h1>2015</h1>
            <span>（上线C端业务）</span>
          </div>
          <ul>
            <TextShade id="item1_li1" ref="item1_li1">
                <template v-slot:shade>
                  <li>灯塔财经注册成立</li>
                </template>
            </TextShade>
            <TextShade id="item1_li2" ref="item1_li2">
                <template v-slot:shade>
                  <li>股票灯塔公众号运营</li>
                </template>
            </TextShade>
            <TextShade id="item1_li3" ref="item1_li3">
                <template v-slot:shade>
                  <li>股票灯塔APP上线</li>
                </template>
            </TextShade>
          </ul>
        </li>
        <li>
          <div class="hidden" id="item2">
            <h1>2016</h1>
            <span>（产品验证）</span>
          </div>
          <ul>
            <TextShade id="item2_li1" ref="item2_li1">
                <template v-slot:shade>
                  <li>市场排名到11位</li>
                </template>
            </TextShade>
            <TextShade id="item2_li2" ref="item2_li2">
                <template v-slot:shade>
                  <li>用户净增长全行业第一</li>
                </template>
            </TextShade>
          </ul>
        </li>
        <li>
          <div class="hidden" id="item3">
            <h1>2017</h1>
            <span>（启动B端及商业化）</span>
          </div>
          <ul>
            <TextShade id="item3_li1" ref="item3_li1">
                <template v-slot:shade>
                  <li>同步开启C端（增值业务）</li>
                </template>
            </TextShade>
            <TextShade id="item3_li2" ref="item3_li2">
                <template v-slot:shade>
                  <li>B端（证券公司技术赋能）商业化</li>
                </template>
            </TextShade>
          </ul>
        </li>
        <li>
          <div class="hidden" id="item4">
            <h1>2018</h1>
            <span>（达到盈利里程碑）</span>
          </div>
          <ul>
            <TextShade id="item4_li1" ref="item4_li1">
                <template v-slot:shade>
                  <li>实现净利润2072万</li>
                </template>
            </TextShade>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import TextShade from '@/components/animeTxt/TextShade.vue'
import $ from "jquery";
export default {
  data: function () {
    return {};
  },
  components: {
        TextShade
    },
  methods: {
    async playPageStart() {
      await this.page3Fn();
      await sleep(3000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page3Fn() {
      await sleep(100);
      $("#page4 #item1").removeClass("hidden");
      await sleep(1000);
      this.$refs.item1_li1.play();
      await sleep(1000);
      this.$refs.item1_li2.play();
      await sleep(1000);
      this.$refs.item1_li3.play();
      await sleep(1500);
      $("#page4 #item2").removeClass("hidden");
      await sleep(1000);
      this.$refs.item2_li1.play();
      await sleep(1000);
      this.$refs.item2_li2.play();
      await sleep(1500);
      $("#page4 #item3").removeClass("hidden");
      await sleep(1000);
      this.$refs.item3_li1.play();
      await sleep(1000);
      this.$refs.item3_li2.play();
      await sleep(1500);
      $("#page4 #item4").removeClass("hidden");
      await sleep(1000);
      this.$refs.item4_li1.play();
      await sleep(1500);
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335")
    format("truetype");
}
.page4 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 240px;
  }
  > .content {
    > ul {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      color: #ff9c48;
      > li {
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 32px;
          transition: all 1s;
          > h1 {
            font-weight: 400;
            font-size: 64px;
            font-family: "harmonyBold";
          }
          > span {
            font-size: 24px;
            font-weight: 400;
          }
        }
        > ul {
          li {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            &::before {
              content: " ";
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #ff9c48;
              border-radius: 50%;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateX(50px);
}
</style>