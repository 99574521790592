<template>
  <div class="page14">
    <TextShade id="title2" ref="title2">
      <template v-slot:shade>
        <h2>典型产品功能介绍</h2>
      </template>
    </TextShade>
    <TextShade id="p1" ref="p1">
      <template v-slot:shade>
        <p>金融行业服务解决方案：智能内容创作运营平台-弹指文</p>
      </template>
    </TextShade>
    <div class="link_wrap">
      <ul class="link">
        <li class="hidden">创作优质内容</li>
        <li class="hidden line1"></li>
        <li class="hidden">全网公域引流</li>
        <li class="hidden line2"></li>
        <li class="hidden">流量统计分析</li>
        <li class="hidden line3"></li>
        <li class="hidden">用户转化管理</li>
        <li class="hidden line4"></li>
        <li class="hidden">千人千面</li>
        <li class="hidden line5"></li>
        <li class="hidden">沉淀忠诚用户</li>
      </ul>
      <span class="a1 hidden">创作引流</span>
      <span class="a2 hidden">获客管理</span>
      <span class="a3 hidden">精准营销</span>
    </div>
    <ul class="box_wrapper">
      <li class="li1 hidden">
        <ul class="products hidden">
          <li>热榜</li>
          <li>数图</li>
          <li>投资日历</li>
          <li>长图模版</li>
          <li>文章转海报</li>
          <li>素材管理</li>
          <li>收藏...</li>
        </ul>
        <div class="img hidden">
          <img
            style="width: 42px"
            src="../dengtaBP/images/page14/l_arrow1.png"
            alt=""
          />
        </div>
        <ul class="icons hidden">
          <li>
            <img src="../dengtaBP/images/page14/icon1.png" alt="" />
          </li>
          <li><img src="../dengtaBP/images/page14/icon2.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon3.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon4.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon5.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon6.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon7.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon8.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon9.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon10.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon11.png" alt="" /></li>
          <li><img src="../dengtaBP/images/page14/icon12.png" alt="" /></li>
          <li class="last_li">...</li>
        </ul>
      </li>
      <li class="li2 hidden">
        <ul class="left hidden">
          <li>阅读量</li>
          <li>转发量</li>
          <li>点赞量</li>
          <li>智能画像</li>
          <li>渠道统计</li>
          <li>轨迹追踪</li>
        </ul>
        <div class="arrow hidden">
          <img src="../dengtaBP/images/page14/l_arrow2.png" alt="" />
        </div>
        <div class="right_box hidden">
          <ul class="right">
            <li>快捷回复</li>
            <li>智能名片</li>
            <li>兴趣偏好</li>
            <li>活跃时间</li>
            <li>用户标签</li>
          </ul>
        </div>
      </li>
      <li class="li3 hidden">
        <ul class="left hidden">
          <li>千人千面</li>
          <li>精准触达</li>
          <li>文章带货</li>
          <li>口碑传播</li>
          <li>分享裂变</li>
        </ul>
        <div class="arrow1 hidden">
          <img src="../dengtaBP/images/page14/l_arrow3.png" alt="" />
        </div>
        <div class="center hidden">私域流量</div>
        <div class="arrow2 hidden">
          <img src="../dengtaBP/images/page14/l_arrow4.png" alt="" />
        </div>
        <div class="right hidden">持续服务</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  components: {
    TextShade,
  },
  data: function () {
    return {};
  },
  methods: {
    async playPageStart() {
      await this.page14Fn();
      await sleep(5000);
      this.$emit("playEnd");
    },
    async page14Fn() {
      await sleep(100);
      this.$refs.title2.play();
      await sleep(1000);
      this.$refs.p1.play();
      await sleep(1000);
      // this.title = anyElement({
      //   targets: document.querySelector("#title"),
      //   isSplitTxt: true,
      //   duration: 1000,
      //   delay: 200,
      //   easing: "easeOutQuint",
      //   inEffect: "fadeIn",
      //   outEffect: "bottomOut",
      // });
      // this.p1 = anyElement({
      //   targets: document.querySelector("#p1"),
      //   isSplitTxt: true,
      //   duration: 1000,
      //   delay: 50,
      //   easing: "easeOutQuint",
      //   inEffect: "rightIn",
      //   outEffect: "bottomOut",
      // });
      // this.title.runIn();
      // await sleep(1500);
      // this.p1.runIn();
      // await sleep(1500);
      //part1
      $(".link_wrap .a1").removeClass("hidden");
      $(".link_wrap .a1").addClass("animate__animated animate__fadeIn ");
        await sleep(500);
      $(".li1").removeClass("hidden");
      $(".li1").addClass("animate__animated animate__fadeInLeft ");
        $(".li1 .products").removeClass("hidden");
      $(".li1 .products").addClass("animate__animated animate__fadeInLeft ");
      $(".link_wrap li").eq(0).removeClass("hidden");
      $(".link_wrap li").eq(0).addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".link_wrap li").eq(1).removeClass("hidden");
      $(".line1").animate({ width: "80px" }, 500);
      $(".li1 .img").removeClass("hidden");
      $(".li1 .img").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
      $(".link_wrap li").eq(2).removeClass("hidden");
      $(".link_wrap li").eq(2).addClass("animate__animated animate__fadeIn ");
      $(".li1 .icons").removeClass("hidden");
      $(".li1 .icons").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
      $(".link_wrap li").eq(3).removeClass("hidden");
      $(".line2").animate({ width: "110px" }, 500);
      await sleep(500);
      //part2
      $(".link_wrap .a2").removeClass("hidden");
      $(".link_wrap .a2").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".li2").removeClass("hidden");
      $(".li2").addClass("animate__animated animate__fadeInLeft ");
       $(".li2 .left").removeClass("hidden");
      $(".li2 .left").addClass("animate__animated animate__fadeInLeft ");
      $(".link_wrap li").eq(4).removeClass("hidden");
      $(".link_wrap li").eq(4).addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      $(".link_wrap li").eq(5).removeClass("hidden");
      $(".line3").animate({ width: "80px" }, 500);
      $(".li2 .arrow").removeClass("hidden");
      $(".li2 .arrow").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
      $(".link_wrap li").eq(6).removeClass("hidden");
      $(".link_wrap li").eq(6).addClass("animate__animated animate__fadeIn ");
      $(".li2 .right_box").removeClass("hidden");
      $(".li2 .right_box").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
      $(".link_wrap li").eq(7).removeClass("hidden");
      $(".line4").animate({ width: "110px" }, 500);
      await sleep(1000);
      //part3
      $(".link_wrap .a3").removeClass("hidden");
      $(".link_wrap .a3").addClass("animate__animated animate__fadeIn ");
        await sleep(500);
      $(".li3").removeClass("hidden");
      $(".li3").addClass("animate__animated animate__fadeInLeft ");
      $(".link_wrap li").eq(8).removeClass("hidden");
      $(".link_wrap li").eq(8).addClass("animate__animated animate__fadeIn ");
      $(".li3 .left").removeClass("hidden");
      $(".li3 .left").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
      $(".li3 .arrow1").removeClass("hidden");
      $(".li3 .arrow1").addClass("animate__animated animate__fadeInLeft ");
      $(".li3 .center").removeClass("hidden");
      $(".li3 .center").addClass("animate__animated animate__fadeInLeft ");
      $(".link_wrap .line5").removeClass("hidden");
      $(".line5").animate({ width: "80px" }, 500);
       await sleep(1000);
      $(".link_wrap li").eq(10).removeClass("hidden");
      $(".link_wrap li").eq(10).addClass("animate__animated animate__fadeIn ");
      $(".li3 .arrow2").removeClass("hidden");
      $(".li3 .arrow2").addClass("animate__animated animate__fadeInLeft ");
      $(".li3 .right").removeClass("hidden");
      $(".li3 .right").addClass("animate__animated animate__fadeInLeft ");
      await sleep(1000);
    },
  },
};
</script>
<style lang="less" scoped>
.page14 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 90px;
  }
  #p1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 58px;
    color: #fe9805;
    margin-bottom: 79px;
  }
  .link_wrap {
    width: 100%;
    padding-top: 25px;
    .link {
      width: 100%;
      display: flex;
      align-items: center;
      li {
        font-size: 24px;
        font-weight: bold;
        box-sizing: border-box;
        margin-right: 13px;
      }
      .line1 {
        width: 0px;
        border-top: 3px solid rgba(254, 152, 5);
        height: 2px;
      }
      .line2 {
        height: 2px;
        width: 0px;
        background: linear-gradient(90deg, #e38823 0%, #3688ff 100%);
      }
      .line3 {
        width: 0px;
        border-top: 3px solid rgba(54, 136, 255);
        margin-top: 3px;
        height: 2px;
      }
      .line4 {
        width: 0px;
        height: 2px;
        background: linear-gradient(90deg, #3688ff 0%, #229a30 100%);
      }
      .line5 {
        border-top: 3px solid rgba(34, 154, 48);
      }
      li:nth-of-type(1),
      li:nth-of-type(3) {
        color: #fe9805;
      }
      li:nth-of-type(5),
      li:nth-of-type(7) {
        color: #3889ff;
      }

      li:nth-of-type(9),
      li:nth-of-type(11) {
        color: #1bd330;
      }
      li:nth-of-type(2),
      li:nth-of-type(6),
      li:nth-of-type(10) {
        display: inline-block;
        img {
          width: 0px;
          margin-top: 6px;
          height: 28px;
        }
      }
      li:nth-of-type(4),
      li:nth-of-type(8) {
        display: inline-block;
        img {
          margin-top: 6px;
        }
      }
    }
    .a1 {
      font-size: 30px;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      color: #fe9805;
      position: absolute;
      left: 276px;
      top: 329px;
    }
    .a2 {
      font-size: 30px;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      color: #3889ff;
      position: absolute;
      left: 898px;
      top: 329px;
    }
    .a3 {
      font-size: 30px;
      font-family: HarmonyOS Sans SC;
      font-weight: bold;
      color: #1bd330;
      position: absolute;
      right: 320px;
      top: 329px;
    }
  }

  .box_wrapper {
    float: left;
    .li1 {
      position: absolute;
      left: 106px;
      bottom: 158px;
      display: flex;
      width: 450px;
      height: 441px;
      border: 1px dashed #f89404;
      background: linear-gradient(
        270deg,
        rgba(254, 152, 5, 0) 0%,
        rgba(254, 152, 5, 0.16) 100%
      );
      border-radius: 12px;
      .products {
        position: absolute;
        left: 32px;
        top: 32px;
        li {
          width: 128px;
          height: 40px;
          line-height: 40px;
          background: rgba(255, 240, 224);
          border: 1px solid #fea644;
          border-radius: 20px;
          color: #e38823;
          text-align: center;
          margin-bottom: 15px;
        }
      }
      .img {
        position: absolute;
        left: 188px;
        top: 207px;
        width: 42px;
      }
      .icons {
        position: absolute;
        top: 38px;
        right: 32px;
        width: 144px;
        height: 380px;
        background: rgba(255, 240, 224, 0.39);
        border: 1px solid #fea644;
        border-radius: 24px;
        padding: 24px;
        box-sizing: border-box;
        li {
          width: 40px;
          margin-right: 6px;
          margin-bottom: 15px;
          float: left;
        }
        .last_li {
          width: 32px;
          height: 45px;
          font-size: 32px;
          font-weight: 500;
          line-height: 15px;
          color: #e38823;
          position: absolute;
          left: 56px;
          bottom: -25px;
        }
      }
    }
    .li2 {
      width: 450px;
      height: 441px;
      border: 1px dashed #3889ff;
      background: linear-gradient(
        270deg,
        rgba(56, 137, 255, 0) 0%,
        rgba(56, 137, 255, 0.16) 100%
      );
      border-radius: 12px;
      position: absolute;
      left: 730px;
      bottom: 157px;
      display: flex;
      .left {
        position: absolute;
        left: 32px;
        top: 51px;
        li {
          width: 128px;
          height: 40px;
          line-height: 40px;
          background: rgba(245, 249, 255);
          border: 1px solid #3688ff;
          border-radius: 20px;
          color: #3688ff;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .arrow {
        position: absolute;
        left: 170px;
        top: 209px;
        width: 64px;
      }
      .right_box {
        font-family: PingFang SC;
        position: absolute;
        width: 166px;
        height: 358px;
        background: rgba(234, 242, 255);
        border: 1px solid #3688ff;
        border-radius: 4px;
        position: absolute;
        top: 48px;
        right: 34px;
        border-radius: 24px;
        .right {
          position: absolute;
          left: 19px;
          top: 44px;
        }
        li {
          width: 128px;
          height: 40px;
          line-height: 40px;
          background: rgba(245, 249, 255, 0.39);
          border: 1px solid #3688ff;
          border-radius: 20px;
          font-size: 20px;
          color: #3688ff;
          font-weight: 500;
          text-align: center;
          margin-bottom: 16px;
        }
      }
    }
    .li3 {
      width: 450px;
      height: 441px;
      border: 1px dashed #1bd330;
      background: linear-gradient(
        270deg,
        rgba(27, 211, 48, 0) 0%,
        rgba(27, 211, 48, 0.16) 100%
      );
      border-radius: 12px;
      position: absolute;
      right: 108px;
      bottom: 157px;
      display: flex;
      align-items: center;
      padding: 78px 32px;
      box-sizing: border-box;
      justify-content: center;
      .left {
        position: absolute;
        left: 62px;
        top: 16px;
        li {
          width: 72px;
          height: 72px;
          line-height: 36px;
          background: rgba(229, 248, 232);
          border: 1px solid #229a30;
          border-radius: 12px;
          color: #229a30;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 6px;
          margin-bottom: 10px;
        }
      }
      .arrow1 {
        position: absolute;
        left: 146px;
        top: 209px;
        width: 35px;
      }
      .center {
        width: 72px;
        height: 72px;
        line-height: 36px;
        background: rgba(229, 248, 232);
        border: 1px solid #229a30;
        border-radius: 12px;
        color: #229a30;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 6px;
        margin-bottom: 10px;
        position: absolute;
        left: 189px;
        top: 181px;
        text-align: center;
      }
      .arrow2 {
        position: absolute;
        right: 136px;
        top: 209px;
        width: 35px;
      }
      .right {
        position: absolute;
        top: 181px;
        right: 48px;
        width: 72px;
        height: 72px;
        line-height: 36px;
        background: rgba(229, 248, 232);
        border: 1px solid #229a30;
        border-radius: 12px;
        color: #229a30;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 6px;
      }
    }
  }
}
.hidden {
  opacity: 0;
}
</style>