<template>
    <div class="page31">
        <div class="bg entrance" id="bg"></div>
        <div class="content">
             <TextShade id="title" ref="title">
                <template v-slot:shade>
                    <h1>感谢观看</h1>
                </template>
            </TextShade>
            <p id="des">灯塔财经</p>
            <img src="./images/logo.svg" alt="" class="hidden" id="logo" >
            <p class="hidden" id="address">中国<span />武汉</p>
        </div>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import TextShade from '@/components/animeTxt/TextShade.vue'
import $ from "jquery";

export default {
    data: function() {
        return {
        }
    },
     components: {
        TextShade
    },
    methods: {
        async playPageStart() {
            await this.page31Fn();
            // this.$emit("playEnd");
        },
        async page31Fn() {
            this.des = anyElement({
                targets: document.querySelector("#des"),
                isSplitTxt: true,
                duration: 1000,
                delay: 100,
                easing: "easeOutQuint",
                inEffect: "rotateIn1",
                outEffect: "bottomOut",
            });
            $("#bg").removeClass("entrance");
            await sleep(500);
            this.$refs.title.play()
            await sleep(1000);
            this.des.runIn();
            await sleep(1000);
            $("#logo, #address").removeClass("hidden");
            await sleep(2000);
        },
    },
}
</script>
<style lang="less" scoped>
@font-face {
  font-family: "harmonyBold";
  src: url("../../../../assets/fonts/HarmonyOS_Sans_SC_Bold.ttf?t=1638171342335") format("truetype");
}
.page31 {
    width: 100%;
    height: 100%;
    .bg {
        position: absolute;
        width: 64%;
        margin-left: 36%;
        border-left-width: 16vw;  /*有斜边的方向，数值的大小决定斜边的梯度率*/
        border-bottom-width: 100vh; /*梯形的高*/
        border-style: solid;
        border-color: transparent transparent rgba(27, 11, 14, 0.82) transparent; /*选择显示哪个方向的梯形*/
        z-index: 1;
        box-sizing: border-box;
        transition: all 1s;
    }
    .content {
        position: absolute;
        height: 100%;
        margin-left: 60%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            font-size: 100px;
            color: #FF8331;
            letter-spacing: 20px;
            font-weight: 400;
            font-family: 'harmonyBold';
            margin-bottom: 24px;
        }
        > p {
            font-size: 32px;
            color: #FFFFFF;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: all 1s;
            > span {
                background: #FFFFFF;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                margin: 0 6px;
            }
        }
        > img {
            margin-top: 72px;
            margin-bottom: 24px;
            width: 100px;
            transition: all 1s;
        }
    }
}
.entrance {
    transform: translateX(70vw);
    opacity: 0 !important;
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
</style>
