<template>
    <div class="page7">
        <TextShade id="title" autoPlay="true">
            <template v-slot:shade>
                <h2>投资亮点</h2>
            </template>
        </TextShade>
        <ul>
            <li>
                <div class="hidden" id="item1">市场广阔 团队成熟</div>
                <TextShade id="des1" ref="des1">
                    <template v-slot:shade>
                        <p>信息数据爆炸时代，技术赋能是必争的“高地”，IT投入不断增长；公司团队搭配成熟合理，能力全面</p>
                    </template>
                </TextShade>
            </li>
            <li>
                <div  class="hidden" id="item2">稳健规划 高速成长</div>
                <TextShade id="des2" ref="des2">
                    <template v-slot:shade>
                        <p>公司以成为上市公司为目标，稳健规 划，在现金流平衡的基础上，高速成长，营收增长超过50%，利润超过2000万元</p>
                    </template>
                </TextShade>
            </li>
            <li>
                <div  class="hidden" id="item3">创新的商业模式</div>
                <TextShade id="des3" ref="des3">
                    <template v-slot:shade>
                        <p>将人工智能和互联网IT技术，以软件、智能 应用、SaaS产品及服务的方式提供给C端、B端（券商、银行、上市公司等）</p>
                    </template>
                </TextShade>
            </li>
            <li>
                <div  class="hidden" id="item4">强强联合 战略合作</div>
                <TextShade id="des4" ref="des4">
                    <template v-slot:shade>
                        <p>灯塔财经是全国证券技术标准委员会成员，与超过千家证券公司、银行、金控集团、财关公司、上市公司等达成战略合作</p>
                    </template>
                </TextShade>
            </li>
        </ul>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import TextShade from '@/components/animeTxt/TextShade.vue'
import $ from "jquery";

export default {
    data: function() {
        return {}
    },
    components: {
        TextShade
    },
    methods: {
        async playPageStart() {
            await this.page6Fn();
            await sleep(3000);
            this.$emit("playEnd");
        },
        async page6Fn() {
            await sleep(1000)

            $("#item1").removeClass('hidden')
            await sleep(1000)
            this.$refs.des1.play()
            await sleep(1000)

            $("#item2").removeClass('hidden')
            await sleep(1000)
            this.$refs.des2.play()
            await sleep(1000)

            $("#item3").removeClass('hidden')
            await sleep(1000)
            this.$refs.des3.play()
            await sleep(1000)

            $("#item4").removeClass('hidden')
            await sleep(1000)
            this.$refs.des4.play()
            await sleep(4000)
        }
    },
}
</script>
<style lang="less" scoped>
.page7 {
    height: 100%;
    width: 100%;
    background-color: rgba(27, 11, 14, 0.61);
    padding: 64px 100px;
    box-sizing: border-box;
    overflow: hidden;
    h2 {
        font-size: 48px;
        font-weight: bold;
    }
    > ul {
        margin-top: 90px;
        > li {
            margin-bottom: 46px;
            > div:first-child {
                display: inline-block;
                border-radius: 24px 24px 24px 0px;
                background: #FE9805;
                opacity: 0.8;
                padding: 22px 24px;
                font-size: 32px;
                font-weight: bold;
                min-width: 260px;
                margin-bottom: 22px;
                transition: all 0.7s;
            }
            p {
                color: #FFDAA3;
                font-size: 32px;
                font-weight: 400;
                opacity: 0.8;
            }
        }
    }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
</style>
