<template>
    <div class="page11">
        <video
        src="/videos/product.MP4"
        muted
        ref="Video"
        ></video>
    </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";

export default {
    data: function() {
        return {
        }
    },
    methods: {
        async playPageStart() {
            await this.page10Fn();
            this.$emit("playEnd");
        },
        async page10Fn() {
            this.$refs.Video.play();
            await sleep(1000);
            this.$refs.Video.pause();
            await sleep(1000);
            this.$refs.Video.play();
            await sleep(2000);
        },
    },
}
</script>
<style lang="less" scoped>
.page11 {
    width: 100%;
    height: 100%;
    video {
        width: 100%;
    }
}
</style>
