<template>
  <div class="page3">
    <TextShade id="title" ref="title">
      <template v-slot:shade>
        <h2>项目概况</h2>
      </template>
    </TextShade>
    <div class="content">
      <div id="des">
        <p style="margin-bottom: 36px" id="p1">
          灯塔财经成立于 2015
          年，致力于成为国内领先的财经软件、数据、信息及服务提供商。
        </p>
        <p id="p2">创始团队由国内顶级互联网和金融行业资深高管组成。</p>
        <p id="p3">
          团队成员90%来自于腾讯、阿里、百度等一流互联网和金融企业，拥有10年以上移动互联网、搜索引擎、金融软件领域的经验。
        </p>
      </div>
      <TextShade id="title2" ref="title2">
        <template v-slot:shade>
          <h3>灯塔业务服务</h3>
        </template>
      </TextShade>
      <ul>
        <li id="serve1" class="hidden">
          <span>个人投资者</span>
          <span>1000W+</span>
        </li>
        <li id="serve2" class="hidden">
          <span>金融企业</span>
          <span>70+</span>
        </li>
        <li id="serve3" class="hidden">
          <span>上市公司</span>
          <span>500+</span>
        </li>
        <li id="serve4" class="hidden">
          <span>湖北省&武汉市</span>
          <span>“金种子”</span>
        </li>
        <li id="serve5" class="hidden">
          <span>湖北省&武汉市</span>
          <span>“瞪羚企业”</span>
        </li>
        <li id="serve6" class="hidden">
          <span>胡润财富</span>
          <span>“最具投资价值百强企业”</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import TextShade from "@/components/animeTxt/TextShade.vue";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";

export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page2Fn();
      await sleep(3000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },

    async page2Fn() {
      this.p1 = anyElement({
        targets: document.querySelector("#p1"),
        isSplitTxt: true,
        duration: 1000,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.p2 = anyElement({
        targets: document.querySelector("#p2"),
        isSplitTxt: true,
        duration: 1000,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.p3 = anyElement({
        targets: document.querySelector("#p3"),
        isSplitTxt: true,
        duration: 1000,
        delay: 60,
        easing: "easeOutQuint",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      await sleep(100);
      this.$refs.title.play();
      await sleep(1000);
      this.p1.runIn();
      await sleep(3000);
      this.p2.runIn();
      await sleep(2000);
      this.p3.runIn();
      await sleep(4000);
      this.$refs.title2.play();
      await sleep(1000);
      $("#serve1").removeClass("hidden");
      await sleep(1000);
      $("#serve2").removeClass("hidden");
      await sleep(1000);
      $("#serve3").removeClass("hidden");
      await sleep(1000);
      $("#serve4").removeClass("hidden");
      await sleep(1000);
      $("#serve5").removeClass("hidden");
      await sleep(1000);
      $("#serve6").removeClass("hidden");
      await sleep(1500);
    },
  },
};
</script>
<style lang="less" scoped>
.page3 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  h2 {
    font-size: 48px;
    font-weight: bold;
  }
  .content {
    #des {
      // transition: all 1s;
      margin: 172px 0 64px 0;
      // font-size: 32px;
      // font-weight: 400;
      > p {
        font-size: 32px;
        font-weight: 400;
      }
    }
    h3 {
      color: #fe9805;
      font-weight: bold;
      font-size: 32px;
    }
    > ul {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > li {
        display: flex;
        flex-direction: column;
        transition: all 1s;
        > span:first-child {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 12px;
        }
        > span:last-child {
          font-size: 48px;
          color: #fe9805;
          font-weight: bold;
        }
      }
    }
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.hidden2 {
  //   opacity: 0 !important;
  transform: translateX(-1850px);
}
</style>
