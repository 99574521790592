<template>
  <div class="page19">
    <TextShade id="title" ref="title">
      <template v-slot:shade>
        <h2 >B端产品及服务</h2>
      </template>
    </TextShade>
    <div class="content">
      <TextShade id="desc" ref="desc">
        <template v-slot:shade>
          <span id="p1" style="margin-bottom: 36px">
            供应链金融管理服务平台：公司与产业合作伙伴共同基于大数据、区块链等技术，实现物流、信息流、资金流的全方位管理
            和监控。
          </span>
          <span style="color: #fe9805" id="p2"
            >典型案例：与武汉金控联合打造供应链金融管理服务平台。</span
          >
        </template>
      </TextShade>

      <ul>
        <li class="hidden">
          <img src="../dengtaBP/images/gxl/1.png" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/gxl/2.png" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/gxl/3.png" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/gxl/4.png" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
   components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page19Fn();
      await sleep(5000);
      $(".content").addClass("animate__animated animate__fadeOutLeft");
      await sleep(1000);
      this.$emit("playEnd");
    },
    async page19Fn() {
      await sleep(100);
      this.$refs.title.play();
      await sleep(1000);
      this.$refs.desc.play();
      await sleep(1000);
      $("li").eq(0).removeClass("hidden");
      $("li").eq(0).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(1).removeClass("hidden");
      $("li").eq(1).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(2).removeClass("hidden");
      $("li").eq(2).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(3).removeClass("hidden");
      $("li").eq(3).addClass("animate__animated animate__bounceInRight ");
    },
  },
};
</script>
<style lang="less" scoped>
.page19 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow: hidden;
  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 80px;
  }
  > .content {
    span {
      font-size: 32px;
      font-weight: 400;
      line-height: 58px;
    }
   
    #desc{
      margin-bottom: 64px;
    }
    > ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > li {
        width: 374px;
      }
    }
  }
}

.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.animate__animated.animate__bounceInRight {
  --animate-duration: 1s;
  // --animate-delay: 2s;
}
</style>