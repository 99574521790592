<template>
  <div class="page17">
    <h2 id="title">B端产品及服务</h2>
    <div class="content">
      <TextShade id="desc" ref="desc">
        <template v-slot:shade>
          <span id="p1">
            董蜜SaaS平台：董蜜已形成以web端为主，PC端、移动端和微信服务号为辅的4大产品矩阵，随时随地满足客户的服务使用需求。
          </span>
          <span style="color: #fe9805" id="p2"
            >上市公司试用客户超2,000家，付费客户超500家。并与九富投资达成官方合作推出“董蜜九富通”。</span
          >
        </template>
      </TextShade>
      <ul>
        <li class="hidden">
          <img src="../dengtaBP/images/SaaS/1.svg" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/SaaS/2.svg" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/SaaS/3.svg" alt="" />
        </li>
        <li class="hidden">
          <img src="../dengtaBP/images/SaaS/4.svg" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page17Fn();
      await sleep(6000);
      this.$emit("playEnd");
    },
    async page17Fn() {
      await sleep(100);
      this.$refs.desc.play();
      await sleep(1000);
      $("li").eq(0).removeClass("hidden");
      $("li").eq(0).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(1).removeClass("hidden");
      $("li").eq(1).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(2).removeClass("hidden");
      $("li").eq(2).addClass("animate__animated animate__bounceInRight ");
      await sleep(1000);
      $("li").eq(3).removeClass("hidden");
      $("li").eq(3).addClass("animate__animated animate__bounceInRight ");
    },
  },
};
</script>
<style lang="less" scoped>
.page17 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  overflow: hidden;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 80px;
  }
  .content {
    span {
      font-size: 32px;
      font-weight: 400;
      line-height: 58px;
    }
    #desc{
      margin-bottom: 64px;
    }
    > ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > li {
        width: 374px;
        background: rgba(27, 11, 14, 0.4);
        border-radius: 28px;
      }
    }
  }
}
.hidden {
  opacity: 0 !important;
  transform: translateY(50px);
}
.animate__animated.animate__bounceInRight {
  --animate-duration: 1s;
  // --animate-delay: 2s;
}
</style>