<template>
  <div class="page22">
    <h2 id="title">C端产品及服务—服务创造价值</h2>
    <TextShade id="p1" ref="p1">
      <template v-slot:shade>
        <p id="p1">“股票灯塔”系列软件：累计用户超1000万，市场份额名列前茅</p>
      </template>
    </TextShade>

    <div class="content">
      <div class="left">
        <div>
          <span id="animat1" class="hidden1">留存数据</span>
          <div id="animat2" class="hidden2">
            <span>日留存</span>
            <span>>46%</span>
          </div>
          <div id="animat3" class="hidden2">
            <span>周留存</span>
            <span>>47%</span>
          </div>
          <div id="animat4" class="hidden2">
            <span>月留存</span>
            <span>>54%</span>
          </div>
        </div>
        <div>
          <span id="animat5" class="hidden1">活跃用户</span>
          <div id="animat6" class="hidden3">
            <span>日活/DAU</span>
            <span>50w</span>
          </div>
          <div id="animat7" class="hidden3">
            <span>周活</span>
            <span>70w</span>
          </div>
          <div id="animat8" class="hidden3">
            <span>月活/MAU</span>
            <span>200w</span>
          </div>
        </div>
        <div>
          <span id="animat9" class="hidden1">累计用户</span>
          <ul id="animat10" class="hidden4">
            <li>
              <span>用户总数</span>
              <span>1000w</span>
            </li>
            <li>
              <span>月新增数</span>
              <span>25w</span>
            </li>
          </ul>
          <ul id="animat11" class="hidden4">
            <li>
              <span>用户总数</span>
              <span>1000w</span>
            </li>
            <li>
              <span>月新增数</span>
              <span>25w</span>
            </li>
          </ul>
        </div>
      </div>
      <img src="./images/arrow.svg" alt="" id="animat12" class="hidden1" />
      <div class="right hidden4" id="animat13">
        <span id="animat14" class="hidden4">同花顺</span>
        <span id="animat15" class="hidden4">大智慧</span>
        <span id="animat16" class="hidden4">牛股王</span>
        <h2 id="animat17" class="hidden4">股票灯塔</h2>
        <span id="animat18" class="hidden4">腾讯自选股</span>
        <span id="animat19" class="hidden4">雪球</span>
        <span id="animat20" class="hidden4">第三方证券服务应用</span>
        <span id="animat21" class="hidden4"
          >MAU用户规模排名<span>第四</span></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { sleep } from "@/utils/utils.js";
import { anyElement } from "@/components/anyElement.js";
import $ from "jquery";
import TextShade from "@/components/animeTxt/TextShade.vue";
export default {
  data: function () {
    return {};
  },
  components: {
    TextShade,
  },
  methods: {
    async playPageStart() {
      await this.page22Fn();
      await sleep(3000);
      this.$emit("playEnd");
    },
    async page22Fn() {
      // this.p1 = anyElement({
      //     targets: document.querySelector("#p1"),
      //     isSplitTxt: true,
      //     duration: 1000,
      //     delay: 100,
      //     easing: "easeOutQuint",
      //     inEffect: "fadeIn",
      //     outEffect: "bottomOut",
      // });
      // this.p1.runIn();
      await sleep(100);
      this.$refs.p1.play();
      await sleep(1000);
      $("#animat1").removeClass("hidden1");
      $("#animat2").removeClass("hidden2");
      $("#animat3").removeClass("hidden2");
      $("#animat4").removeClass("hidden2");
      $("#animat5").removeClass("hidden1");
      $("#animat6").removeClass("hidden3");
      $("#animat7").removeClass("hidden3");
      $("#animat8").removeClass("hidden3");
      $("#animat9").removeClass("hidden1");
      $("#animat10").removeClass("hidden4");
      $("#animat11").removeClass("hidden4");
      await sleep(1000);
      $("#animat12").removeClass("hidden1");
      await sleep(1000);
      $("#animat13").removeClass("hidden4");
      $("#animat14").removeClass("hidden4");
      await sleep(300);
      $("#animat15").removeClass("hidden4");
      await sleep(300);
      $("#animat16").removeClass("hidden4");
      await sleep(300);
      $("#animat17").removeClass("hidden4");
      await sleep(300);
      $("#animat18").removeClass("hidden4");
      await sleep(300);
      $("#animat19").removeClass("hidden4");
      await sleep(300);
      $("#animat20").removeClass("hidden4");
      await sleep(300);
      $("#animat21").removeClass("hidden4");
      await sleep(3000);
    },
  },
};
</script>
<style lang="less" scoped>
.page22 {
  height: 100%;
  width: 100%;
  background-color: rgba(27, 11, 14, 0.61);
  padding: 64px 120px 100px;
  box-sizing: border-box;
  > h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 64px;
  }
  p {
    font-size: 32px;
    font-weight: bold;
    color: #fe9805;
    margin-bottom: 68px;
  }
  .content {
    display: flex;
    align-items: center;
    > img {
      width: 64px;
      margin: 0 48px;
      transition: all 1s;
    }
    .left {
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 64px;
        &:last-child {
          margin-bottom: 0;
        }
        > span {
          font-weight: bold;
          font-size: 32px;
          width: 64px;
          transition: all 1s;
        }
        > div {
          width: 240px;
          padding: 40px 0;
          border: 1px dashed #ffdaa3;
          border-radius: 8px;
          background: linear-gradient(
            270deg,
            rgba(254, 152, 5, 0) 0%,
            rgba(254, 152, 5, 0.16) 100%
          );
          margin-left: 64px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 1s;
          > span {
            font-size: 24px;
            &:last-child {
              font-size: 48px;
              color: #fe9805;
              font-weight: bold;
              margin-top: 4px;
            }
          }
        }
        > ul {
          width: 314px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px;
          border: 1px dashed #ffdaa3;
          border-radius: 8px;
          background: linear-gradient(
            270deg,
            rgba(254, 152, 5, 0) 0%,
            rgba(254, 152, 5, 0.16) 100%
          );
          margin-left: 64px;
          transition: all 1s;
          > li {
            display: flex;
            flex-direction: column;
            align-items: center;
            > span {
              font-size: 24px;
              &:last-child {
                font-size: 48px;
                color: #fe9805;
                font-weight: bold;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 1px dashed #ffdaa3;
      border-radius: 8px;
      background: linear-gradient(
        270deg,
        rgba(254, 152, 5, 0) 0%,
        rgba(254, 152, 5, 0.16) 100%
      );
      height: 676px;
      padding: 12px 64px;
      font-weight: 400;
      font-size: 24px;
      box-sizing: border-box;
      transition: all 0.7s;
      > h2 {
        font-size: 48px;
        color: #fe9805;
        font-weight: bold;
        transition: all 1s;
      }
      > span {
        transition: all 1s;
        > span {
          font-size: 32px;
          color: #fe9805;
        }
      }
    }
  }
  .hidden1 {
    opacity: 0 !important;
    transform: translateX(-50px);
  }
  .hidden2 {
    opacity: 0 !important;
    transform: translateY(-50px);
  }
  .hidden3 {
    opacity: 0 !important;
    transform: translateX(50px);
  }
  .hidden4 {
    opacity: 0 !important;
    transform: translateY(50px);
  }
  .hidden5 {
    opacity: 0 !important;
  }
}
</style>